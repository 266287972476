import { Injectable, inject } from '@angular/core';
import { SupportedLanguage } from '../types/market.types';
import { RentTimeUnit } from '../types/product.types';
import { EpiService } from './epi/epi.service';

@Injectable()
export class PriceUtilsService {
	private epiService = inject(EpiService);

	/**
	 * @deprecated these translations are now part of AppData.timeUnits
	 */
	public getTimeUnitTranslation({ timeUnit, plural }: { timeUnit: RentTimeUnit; plural: boolean }): string {
		const appTextGlobal = this.epiService.appData.global;

		if (timeUnit === 'RWEEK') {
			if (plural) {
				return appTextGlobal.weeks;
			}
			return appTextGlobal.week;
		} else if (timeUnit === 'MONTH') {
			if (plural) {
				return appTextGlobal.months;
			}
			return appTextGlobal.month;
		} else if (timeUnit === 'RMNTH') {
			if (plural) {
				return appTextGlobal.rentMonths;
			}
			return appTextGlobal.rentMonth;
		} else if (timeUnit === '30D') {
			if (plural) {
				return appTextGlobal.thirtyDays;
			}
			return appTextGlobal.thirtyDay;
		} else if (timeUnit === 'WDAY') {
			if (plural) {
				return appTextGlobal.workdays;
			}
			return appTextGlobal.workday;
		} else if (timeUnit === '6DAY') {
			if (plural) {
				return appTextGlobal.sixdays;
			}
			return appTextGlobal.sixday;
		} else if (timeUnit === 'DAY') {
			if (plural) {
				return appTextGlobal.days;
			}
			return appTextGlobal.day;
		} else {
			return timeUnit;
		}
	}

	/**
	 * @deprecated these translations are now part of AppData.units
	 */
	public getUnitTranslation({ unit, language }: { unit: string; language: SupportedLanguage }): string {
		if (unit === 'DRUM') {
			if (language === 'sv') return `Trumma 208l`;
			if (language === 'no') return `Tromme 208l`;
			if (language === 'fi') return `Rumpu 208l`;
			if (language === 'et') return `208 l vaat`;
			if (language === 'lt') return `208L būgnas`;
			if (language === 'lv') return `208L Muca`;
			if (language === 'en') return `DRUM 208l`;
		} else if (unit === 'HR') {
			if (language === 'sv') return `Timme`;
			if (language === 'no') return `Time`;
			if (language === 'fi') return `Tunti`;
			if (language === 'et') return `Tund`;
			if (language === 'lt') return `Valanda`;
			if (language === 'lv') return `Stunda`;
			if (language === 'en') return `Hour`;
		} else if (unit === 'KG') {
			if (language === 'sv') return `Kilogram`;
			if (language === 'no') return `Kilo`;
			if (language === 'fi') return `Kilo`;
			if (language === 'et') return `Kilogramm`;
			if (language === 'lt') return `Kilogramas`;
			if (language === 'lv') return `Kilograms`;
			if (language === 'en') return `Kilogram`;
		} else if (unit === 'KM') {
			if (language === 'sv') return `Kilometer`;
			if (language === 'no') return `Kilometer`;
			if (language === 'fi') return `Kilometri`;
			if (language === 'et') return `Kilomeeter`;
			if (language === 'lt') return `Kilometras`;
			if (language === 'lv') return `Kilometrs`;
			if (language === 'en') return `Kilometer`;
		} else if (unit === 'KWH') {
			if (language === 'sv') return `Kilowattimme(ar)`;
			if (language === 'no') return `Kilowattime`;
			if (language === 'fi') return `Kilowattitunti(a)`;
			if (language === 'et') return `Kilovatttund(i)`;
			if (language === 'lt') return `Kilovatvalandė`;
			if (language === 'lv') return `Kilovatstunda`;
			if (language === 'en') return `Kilowatt hour`;
		} else if (unit === 'L') {
			if (language === 'sv') return `Liter`;
			if (language === 'no') return `Liter`;
			if (language === 'fi') return `Litra`;
			if (language === 'et') return `Liiter`;
			if (language === 'lt') return `Litras`;
			if (language === 'lv') return `Litrs`;
			if (language === 'en') return `Liter`;
		} else if (unit === 'M') {
			if (language === 'sv') return `Meter`;
			if (language === 'no') return `Meter`;
			if (language === 'fi') return `Metri`;
			if (language === 'et') return `Meeter`;
			if (language === 'lt') return `Metras`;
			if (language === 'lv') return `Metrs`;
			if (language === 'en') return `Meter`;
		} else if (unit === 'M3') {
			if (language === 'sv') return `Kubikmeter`;
			if (language === 'no') return `Kubikkmeter`;
			if (language === 'fi') return `Kuutiometri`;
			if (language === 'et') return `Kuupmeeter`;
			if (language === 'lt') return `Kūbinis metras`;
			if (language === 'lv') return `Kubikmetrs`;
			if (language === 'en') return `Cubik Meter`;
		} else if (unit === 'MM') {
			if (language === 'sv') return `Millimeter`;
			if (language === 'no') return `Milimeter`;
			if (language === 'fi') return `Millimetri`;
			if (language === 'et') return `Millimeeter`;
			if (language === 'lt') return `Milimetras`;
			if (language === 'lv') return `Milimetrs`;
			if (language === 'en') return `Milimeter`;
		} else if (unit === 'PACK') {
			if (language === 'sv') return `Paket`;
			if (language === 'no') return `Pakke`;
			if (language === 'fi') return `Pakkaus`;
			if (language === 'et') return `Pakk`;
			if (language === 'lt') return `Pakuotė`;
			if (language === 'lv') return `Iepakojums`;
			if (language === 'en') return `Package`;
		} else if (unit === 'PAIR') {
			if (language === 'sv') return `Par`;
			if (language === 'no') return `Par`;
			if (language === 'fi') return `Pari`;
			if (language === 'et') return `Paar`;
			if (language === 'lt') return `Pora`;
			if (language === 'lv') return `Trīs iepakojums`;
			if (language === 'en') return `Pair`;
		} else if (unit === 'PALL') {
			if (language === 'sv') return `Pall`;
			if (language === 'no') return `Pall`;
			if (language === 'fi') return `Lava`;
			if (language === 'et') return `Alus`;
			if (language === 'lt') return `Paletė`;
			if (language === 'lv') return `Palete`;
			if (language === 'en') return `Pallet`;
		} else if (unit === 'PCS') {
			if (language === 'sv') return `Styck`;
			if (language === 'no') return `Stk`;
			if (language === 'fi') return `Kpl`;
			if (language === 'et') return `Tk`;
			if (language === 'lt') return `Vnt.`;
			if (language === 'lv') return `Vienība/Vienības`;
			if (language === 'en') return `Piece/pieces`;
		} else if (unit === 'SQM') {
			if (language === 'sv') return `Kvadratmeter`;
			if (language === 'no') return `Kvadratmeter`;
			if (language === 'fi') return `Neliömetri`;
			if (language === 'et') return `Ruutmeeter`;
			if (language === 'lt') return `Kvadratinis metras`;
			if (language === 'lv') return `Kvadrātmetrs`;
			if (language === 'en') return `Square meter`;
		} else if (unit === 'T') {
			if (language === 'sv') return `Ton`;
			if (language === 'no') return `Ton`;
			if (language === 'fi') return `Tonni`;
			if (language === 'et') return `Tonn`;
			if (language === 'lt') return `Tona`;
			if (language === 'lv') return `Tonna`;
			if (language === 'en') return `Ton`;
		} else if (/^P\d{1,6}$/.test(unit)) {
			// catches P3, P123 and so one.
			if (language === 'sv') return `${this.extractNumbers(unit)}-pack`;
			if (language === 'no') return `${this.extractNumbers(unit)}-pakke`;
			if (language === 'fi') return `${this.extractNumbers(unit)}-pakkausta`;
			if (language === 'et') return `${this.extractNumbers(unit)} tk pakis`;
			if (language === 'lt') return `${this.extractNumbers(unit)}-pakuotės`;
			if (language === 'lv') return `${this.extractNumbers(unit)}-iepakojums`;
			if (language === 'en') return `Package of ${this.extractNumbers(unit)} pieces`;
		}
		console.warn(`missing unit translations for ${unit}`);
		return '';
	}

	private extractNumbers(str: string): number {
		const result = str.replace(/\D/g, '');
		return parseInt(result, 10);
	}
}
