import { Component, EventEmitter, Input, OnInit, Output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import moment, { Moment } from 'moment-mini';
import { take } from 'rxjs/operators';
import { AvailabilityUtilsService } from '../../services/availability-utils.service';
import { EpiService } from '../../services/epi/epi.service';
import {
	MovedOrderLine,
	MoveOrderLineRequestModel,
	MoveOrderLinesRequestModel,
	OrderService,
} from '../../services/order.service';
import { Configurations } from '../../types/configuration.types';
import { Jobsite } from '../../types/jobsite.types';
import { OrderRow, TransformedOrderRow } from '../../types/order.types';
import { AppData } from '../../types/translations.types';
import { CalendarOptions } from '../calendar/calendar-options';

@Component({
	selector: 'cramo-move-orderlines',
	templateUrl: './move-orderlines.component.html',
	styleUrls: ['./move-orderlines.component.scss'],
})
export class MoveOrderLinesComponent implements OnInit {
	@Input() public ordersToMove: (Omit<TransformedOrderRow, 'OrderRow'> & {
		OrderRow: TransformedOrderRow['OrderRow'] & { IsSuccess: boolean | null };
	})[]; //TODO -> SHOULD BE ORDER ROW

	@Input() public jobsite: Jobsite;
	@Output() public closePanel: EventEmitter<boolean> = new EventEmitter();
	@Output() public bySuccess: EventEmitter<MoveOrderLineRequestModel[]> = new EventEmitter();

	public orderImages: Record<OrderRow['OrderId'], { imageUrl: string; hasImage: boolean }> = {};
	public appData: Signal<AppData>;
	public moveOrderLinesEnabled = false;
	public jobsiteTo: Jobsite;
	public sendingRequest = false;
	public plannedReturnDateCalendarOptions: CalendarOptions;
	private configurations: Configurations;
	private plannedReturnDate: Date;
	public IsSuccess: boolean = undefined;
	public isConsentToggled = false;
	public isMovingOrderLinesInProgress: boolean = false;
	public isConfirmButtonVisible: boolean = true;

	constructor(
		private epiService: EpiService,
		public dialogRef: MatDialogRef<MoveOrderLinesComponent>,
		private availabilityUtils: AvailabilityUtilsService,
		private orderService: OrderService,
	) {
		this.appData = toSignal(this.epiService.appData$);
		this.configurations = this.epiService.configurationData;
	}

	ngOnInit(): void {
		this.initPlannedReturnDate();

		this.ordersToMove.forEach((order) => {
			const imageUrl = this.getProductImageUrl(order);
			this.orderImages[order.OrderId] = { imageUrl, hasImage: imageUrl.includes('icon_nophoto') };
		});
	}

	private initPlannedReturnDate(): void {
		const holidays = this.configurations.calendarHolidays;
		const tomorrow = moment().add(1, 'days');
		const firstWorkday = this.availabilityUtils.getFirstWorkDate({ holidays, startingDate: tomorrow });
		this.plannedReturnDate = firstWorkday.toDate();
		this.plannedReturnDateCalendarOptions = new CalendarOptions(
			firstWorkday,
			null,
			true,
			firstWorkday,
			null,
			holidays,
			null,
			true,
			false,
			false,
			false,
			null,
			false,
			true,
		);
		this.plannedReturnDateCalendarOptions.alwaysVisible = false;
	}

	public onReturnDateChange(date: Moment): void {
		this.plannedReturnDate = date.toDate();
	}

	public onJobsiteSelect(jobsite: Jobsite | Jobsite[]): void {
		this.jobsiteTo = jobsite as Jobsite;
		this.isMoveOrderLinesAllowed();
	}

	private isMoveOrderLinesAllowed(): void {
		this.moveOrderLinesEnabled = this.jobsiteTo && this.IsSuccess && this.isConsentToggled === undefined;
	}

	public moveOrderLines(): void {
		this.isMovingOrderLinesInProgress = true;
		this.isConfirmButtonVisible = false;
		const moveOrderLines = this.ordersToMove
			.filter((e) => !e.OrderRow.IsSuccess)
			.map((e) => new MoveOrderLineRequestModel(e.OrderId, e.OrderRow.Id));

		const request = new MoveOrderLinesRequestModel(
			this.jobsite.Id,
			this.jobsiteTo.Id,
			this.plannedReturnDate,
			moveOrderLines,
		);

		this.sendingRequest = true;
		this.orderService
			.moveOrderLinesBetweenJobsites(request)
			.pipe(take(1))
			.subscribe((orderLinesResponse: MovedOrderLine[]) => {
				this.ordersToMove.forEach((o) => {
					o.OrderRow.IsSuccess = orderLinesResponse.some(
						(movedOrderLine) =>
							movedOrderLine.OrderId === o.OrderId &&
							movedOrderLine.OrderRowId === o.OrderRow.Id &&
							movedOrderLine.IsSuccess,
					);
				});

				const unSuccessfulOrderlines = orderLinesResponse.filter((o) => !o.IsSuccess);
				const successfulOrderlines = orderLinesResponse.filter((o) => o.IsSuccess);

				if (successfulOrderlines.length > 0) {
					this.bySuccess.emit(successfulOrderlines);
				}

				if (unSuccessfulOrderlines.length > 0) {
					this.IsSuccess = false;
					this.ordersToMove.sort((a, b) => Number(a.OrderRow.IsSuccess) - Number(b.OrderRow.IsSuccess));
				} else {
					this.IsSuccess = true;
				}

				this.isMoveOrderLinesAllowed();
				this.sendingRequest = false;
				this.isMovingOrderLinesInProgress = false;
			});
	}

	private getProductImageUrl(order: TransformedOrderRow): string {
		let url = order.product ? order.product.files.images[0].url : '';
		url = order.serialImage ? order.serialImage : url;
		if (url.length === 0) {
			url = '/assets/icons/icon_nophoto.svg';
		} else {
			url = `${url}?width=160`;
		}
		return url;
	}

	public toggleCheckbox() {
		this.isConsentToggled = !this.isConsentToggled;
		this.isMoveOrderLinesAllowed();
	}
}
