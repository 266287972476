import { CommonModule } from '@angular/common';
import { Component, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FadeInImageOnLoadDirective } from '../../directives/fade-in-on-load.directive';
import { EpiService } from '../../services/epi/epi.service';
import { AppData } from '../../types/translations.types';
import { ButtonComponent } from '../button/button.component';
import { CardComponent } from '../card/card.component';
import { AddToCartSuccessDialogService } from './services/add-to-cart-success-dialog.service';
import { CartOrderRow } from './types/add-to-cart-success-dialog.types';

export type AddToCartSuccessDialogData = { cartOrderRowId: string };
@Component({
	standalone: true,
	imports: [CommonModule, ButtonComponent, FadeInImageOnLoadDirective, RouterModule, CardComponent, MatDialogModule],
	providers: [AddToCartSuccessDialogService],
	templateUrl: './add-to-cart-success-dialog.component.html',
	styleUrl: './add-to-cart-success-dialog.component.scss',
})
export class AddToCartSuccessDialogComponent {
	private addToCartSuccessDialogService = inject(AddToCartSuccessDialogService);
	private dialogRef = inject(MatDialogRef);
	private data: AddToCartSuccessDialogData = inject(MAT_DIALOG_DATA);
	private epiService = inject(EpiService);

	public orderRow: Signal<CartOrderRow | undefined>;
	public appData: Signal<AppData>;

	constructor() {
		this.appData = toSignal(this.epiService.appData$);

		this.dialogRef.disableClose = true;
		this.orderRow = toSignal(this.addToCartSuccessDialogService.getCartOrderRowById(this.data.cartOrderRowId));
	}

	public close() {
		this.dialogRef.close();
	}
}
