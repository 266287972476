import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { ProductsComponent } from '../pages/category-page/products/products.component';
import { UnitTranslationPipe } from '../pipes/unit-translation.pipe';
import { AddToCartComponent } from './add-edit-cart-item-old/add-to-cart-old.component';
import { EditCartComponent } from './add-edit-cart-item-old/edit-cart-old.component';
import { AddToCartSuccessDialogComponent } from './add-to-cart-success-modal/add-to-cart-success-dialog.component';
import { ButtonComponent } from './button/button.component';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { CommonElementsModule } from './common-elements.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CreditControlInformationComponent } from './credit-control-information/credit-control-information-component';
import { DepotAvailabilityBadgeComponent } from './depot-availability-badge/depot-availability-badge.component';
import { DepotSelectComponent } from './depot-select/depot-select.component';
import { FlipPanelComponent } from './flip-panel/flip-panel.component';
import { IconComponent } from './icon/icon.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { MoveOrderLinesComponent } from './move-orderlines/move-orderlines.component';
import { OverlayComponent } from './overlay/overlay.component';
import { ProductAvailabilityComponent } from './product-availability/product-availability.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductPriceComponent } from './product-price/product-price.component';
import { SaveProductButtonComponent } from './save-product-button/save-product-button.component';
import { SearchSelectComponent } from './search-select/search-select.component';

@NgModule({
	declarations: [
		OverlayComponent,
		SearchSelectComponent,
		AddToCartComponent,
		CalendarMonthComponent,
		DepotAvailabilityBadgeComponent,
		DepotSelectComponent,
		EditCartComponent,
		MoveOrderLinesComponent,
		ImageCarouselComponent,
		ProductAvailabilityComponent,
		ProductCardComponent,
		ProductsComponent,
		ProductPriceComponent,
	],
	exports: [
		OverlayComponent,
		SearchSelectComponent,
		AddToCartComponent,
		CalendarMonthComponent,
		ContactUsComponent,
		DepotAvailabilityBadgeComponent,
		DepotSelectComponent,
		EditCartComponent,
		MoveOrderLinesComponent,
		ImageCarouselComponent,
		ProductAvailabilityComponent,
		ProductCardComponent,
		ProductsComponent,
		ProductPriceComponent,
		SaveProductButtonComponent,
	],
	imports: [
		ButtonComponent,
		CommonElementsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		IconComponent,
		MessageBoxComponent,
		AddToCartSuccessDialogComponent,
		SaveProductButtonComponent,
		CreditControlInformationComponent,
		FlipPanelComponent,
		HasPermissionDirective,
		UnitTranslationPipe,
		ContactUsComponent,
	],
})
export class ProductElementsModule {}
