import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { ContactInformation } from '../../types/configuration.types';
import { AppData } from '../../types/translations.types';

@Component({
	selector: 'cramo-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
	standalone: true,
})
export class ContactUsComponent implements OnInit {
	public appData: AppData;
	public contactInfoForMarket: ContactInformation | null;

	private currentLanguage: string;

	constructor(
		private epiService: EpiService,
		private router: Router,
		private marketService: MarketService,
	) {
		this.appData = this.epiService.appData;
		this.currentLanguage = this.marketService.currentLanguage;
	}

	ngOnInit() {
		this.contactInfoForMarket = this.epiService.configurationData.contactInformation;
	}

	public goToDepotMap() {
		this.router.navigateByUrl(`/${this.currentLanguage}/depot`);
	}
}
