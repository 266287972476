import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	signal,
	Signal,
	ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment-mini';
import { EMPTY, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AvailabilityUtilsService } from '../../services/availability-utils.service';
import { CartService } from '../../services/cart/cart.service';
import { ConfigurationService } from '../../services/configuration.service';
import { CreditCheckService } from '../../services/credit-check.service';
import { EpiUtilsService } from '../../services/epi-utils.service';
import { LogisticsRulesDto } from '../../services/epi/epi.model';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { ProductUtilsService } from '../../services/product-utils.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user/user.service';
import { AddEditCartItemOptions } from '../../types/cart-old.types';
import { Configurations, FeatureToggles } from '../../types/configuration.types';
import { SupportedMarket } from '../../types/market.types';
import { AvailabilityResponseDto, Product, ProductFiles, ProductPriceInfo } from '../../types/product.types';
import { AppData } from '../../types/translations.types';
import { formatDate } from '../../utils/date';
import { CreditControlInformationComponent } from '../credit-control-information/credit-control-information-component';

@Component({
	selector: 'cramo-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, OnDestroy, AfterViewInit {
	private showDepotAvailability = false;
	public viewportReady: Subscription;
	public inViewPort = false;
	public currentLanguage: string;
	public productResponse: Product;
	public availability: AvailabilityResponseDto;
	public loadingProduct = false;
	public productFiles: ProductFiles;
	public configurations: Configurations;
	public logisiticsRules: LogisticsRulesDto;
	public productOptions: AddEditCartItemOptions;
	public infoVisible = false;
	public rentLabel = '';
	public showLogin = false;
	public showContactUs = false;
	public isLoggedIn = false;
	public market: SupportedMarket;
	public canRent = false;
	public productLink: string;
	public onDestroy$ = new Subject();
	public prices$: Observable<ProductPriceInfo[]>;
	public appData: AppData;
	public showAvailability = true;
	public showPrice = true;
	public hasLoadedSavedProducts = false;
	public is247Product = false;
	public isMerchandise = false;
	public fetchingPricesFailed = false;

	public featureToggles: Signal<FeatureToggles>;
	public isCreditApproved: Signal<boolean>;
	public isAddingToCart: Signal<boolean>;
	protected readonly prices = signal<ProductPriceInfo[]>(null);

	@Input()
	public set product(value: Product) {
		if (value) {
			this.productResponse = value;
			this.parseProduct();
		}
	}

	public get product(): Product {
		return this.productResponse;
	}

	@Input()
	public set index(_value: unknown) {
		this.checkViewPort();
	}

	@ViewChild('productCardSelector') public productCardRef: ElementRef;

	constructor(
		private productService: ProductService,
		private productUtil: ProductUtilsService,
		private epiService: EpiService,
		private epiUtilsService: EpiUtilsService,
		private configurationService: ConfigurationService,
		private router: Router,
		private availabilityUtils: AvailabilityUtilsService,
		private marketService: MarketService,
		private authService: AuthService,
		private userService: UserService,
		private creditCheckService: CreditCheckService,
		private cartService: CartService,
		private dialog: MatDialog,
	) {
		this.featureToggles = toSignal(this.configurationService.featureToggles$);
		this.isCreditApproved = toSignal(this.creditCheckService.isCreditApproved$);
		this.isAddingToCart = toSignal(this.cartService.isAddingToCart$);
		this.configurations = this.epiService.configurationData;
		this.logisiticsRules = this.epiService.logisticsRules;
		this.currentLanguage = this.marketService.currentLanguage;
		this.appData = this.epiService.appData;
		this.market = this.marketService.currentMarket;
		this.rentLabel = this.appData?.addToCart?.unrentableLabel ?? '';

		this.userService.isLoggedIn$.pipe(takeUntil(this.onDestroy$)).subscribe((isAuthenticated) => {
			this.isLoggedIn = isAuthenticated;
			this.getRentButtonText();
		});
	}

	public ngOnInit(): void {
		this.showDepotAvailability = this.featureToggles().order.isDepotAvailabilityEnabled;

		this.viewportReady = fromEvent(window, 'scroll')
			.pipe(takeUntil(this.onDestroy$), debounceTime(500))
			.subscribe(() => {
				this.checkViewPort();
			});

		if (!this.isLoggedIn) {
			this.showPrice = this.epiUtilsService.hasAnonymousPricing(this.market);
		}
	}

	public ngAfterViewInit(): void {
		// Viewport believes the product cards exist in the bottom of the screen SOMETIMES.
		// Todo: Remake this as an observable
		setTimeout(() => {
			this.userService.user$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.onUserChange());
			this.checkViewPort();
		}, 500);
	}

	public checkViewPort(): void {
		setTimeout(() => {
			if (!this.inViewPort) {
				if (this.isInViewport(this.productCardRef.nativeElement)) {
					this.inViewPort = true;
					this.viewportReady.unsubscribe();

					this.getPrices();
					this.getProductAvailability();
					this.productOptions = {
						product: this.productResponse,
					};
				}
			}
		}, 0);
	}

	public openCreditCheckModal() {
		if (this.isCreditApproved()) {
			return;
		}
		this.dialog.open(CreditControlInformationComponent);
	}

	public isInViewport(element: Element): boolean {
		const rect = element.getBoundingClientRect();
		return (
			rect.top + 300 >= 0 &&
			rect.left >= 0 &&
			rect.bottom - 900 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	public login() {
		this.authService.login();
	}

	public showInfo() {
		this.infoVisible = !this.infoVisible;
	}

	public onUserChange() {
		this.logisiticsRules = this.epiService.logisticsRules;

		this.canRent =
			this.userService.hasPermissionSync('RENT_TRANSPORT') ||
			this.userService.hasPermissionSync('RENT_PICKUP') ||
			this.userService.hasPermissionSync('RENT_BOX_PICKUP');
		this.showAvailability = this.shouldShowAvailability();

		if (this.isLoggedIn && this.productCardRef) {
			if (this.isInViewport(this.productCardRef.nativeElement)) {
				this.getPrices();
				this.getProductAvailability();
				this.productOptions = {
					product: this.productResponse,
				};
			}
			this.hasLoadedSavedProducts = true;
		} else {
			this.showPrice = this.epiUtilsService.hasAnonymousPricing(this.market);
		}

		if (this.productResponse) {
			this.getRentButtonText();
		}
	}

	public parseProduct() {
		if (this.productResponse) {
			this.productFiles = this.productUtil.getProductFiles(
				this.productResponse,
				this.marketService.currentMarket.toUpperCase(),
			);
			this.is247Product = this.productUtil.is247Product(this.productResponse);
			this.isMerchandise = this.productUtil.isMerchandise(this.productResponse);
			if (this.product.ImageUrl === null) {
				this.product.ImageUrl = '/assets/icons/icon_nophoto.svg';
			}
			this.loadingProduct = false;
			this.getRentButtonText();
			this.productLink = `/${this.productResponse.LinkUrl}`;
		}
	}

	private getPrices() {
		this.userService.isLoggedIn$.subscribe((userIsAuthenticated) => {
			if (!this.product.ShowPrice) {
				this.showPrice = false;
				return;
			}

			if (userIsAuthenticated || this.epiUtilsService.hasAnonymousPricing(this.market)) {
				this.showPrice = true;
			} else {
				this.showPrice = false;
				return;
			}

			this.productService
				.getProductPrices({
					itemNumber: this.product.Properties.General.BrandTypeItemNumber.Value,
					marketId: this.market,
					purchaseType: this.isMerchandise ? 'Sale' : 'Rent',
					deliveryDate: formatDate(new Date(), 'YYYY-MM-DD'),
					isAnonymous: !userIsAuthenticated,
				})
				.pipe(
					tap((prices) => {
						this.prices.set(prices);
					}),
					catchError(() => {
						this.fetchingPricesFailed = true;
						return EMPTY;
					}),
				)
				.subscribe();
		});
	}

	public getProductAvailability() {
		// Updated fetching of availability
		this.showAvailability = this.shouldShowAvailability();

		if (this.showAvailability === false) {
			return;
		}

		if (this.isLoggedIn) {
			const availabilityData$ = this.productService.getCachedAvailability(
				this.availabilityUtils.addWeekDays(moment(), 2, this.configurations.calendarHolidays).format('YYYY-MM-DD'),
				this.productResponse.Properties.General.BrandTypeItemNumber.Value,
			);

			availabilityData$.pipe(take(1)).subscribe((data) => {
				this.availability = data[0];
			});
		}
	}

	public shouldShowAvailability() {
		if (this.productResponse) {
			return (
				this.showDepotAvailability &&
				this.availabilityUtils.checkAvailabilityVisibility(
					this.epiService,
					this.isLoggedIn,
					this.canRent,
					this.productResponse,
					this.logisiticsRules,
				)
			);
		} else {
			return false;
		}
	}

	public getRentButtonText() {
		if (!this.productResponse) {
			return;
		}
		const buttonState = this.epiService.getProductButtonState(this.productResponse.ProductCta);
		this.rentLabel = buttonState.text;
		this.showLogin = buttonState.showLogin;
		this.showContactUs = buttonState.showContactUs;
	}

	public goToDepotMap() {
		this.router.navigateByUrl(`/${this.currentLanguage}/depot`);
	}

	public ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public addToCart(productId: string) {
		this.cartService.addToCart(productId).pipe(take(1)).subscribe();
	}
}
