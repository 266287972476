@if (prices()) {
	<div class="price-container">
		@if (defaultPrice()) {
			<div class="price">
				<div class="price-top">
					<span class="price-timeunit">{{ defaultPrice().TimeUnit | unitTranslation }}</span>
					<button class="price-details-button" (click)="openPriceDetailsModal()">
						{{ appData().product?.viewPriceDetails }}
					</button>
				</div>
				<div>
					<span class="price-amount">
						@if (featureToggles().price.isVatDefaultPrice) {
							{{ defaultPrice().PriceInclVat | number: '1.2-2' }}
						} @else {
							{{ defaultPrice().Price | number: '1.2-2' }}
						}
					</span>
					<span class="price-currency"> {{ currency }} </span>
					<span class="price-vat">
						@if (featureToggles().price.isVatDefaultPrice) {
							{{ appData().product?.inclVAT }}
						} @else {
							{{ appData().product?.exclVAT }}
						}
					</span>
				</div>
				@if (featureToggles().insurance.isInsuranceInformationForProductsEnabled) {
					<div>
						<span class="price-insurance">
							{{
								defaultPrice().InsuranceAmountExcludingVatPerTimeUnit +
									(featureToggles()?.price?.isVatDefaultPrice ? defaultPrice().InsuranceVatAmountPerTimeUnit : 0)
									| number: '1.2-2'
							}}
							{{ currency }} {{ appData()?.product?.insuranceIsAdded }}
							@if (featureToggles().insurance.isInsurancePercentageForProductsEnabled) {
								({{ defaultPrice().InsuranceInPercent }}%)
							}
						</span>
					</div>
				}
			</div>
		}
		<ng-template #noPriceSection>
			<div class="price-missing">{{ appData().global?.priceMissing }}</div>
		</ng-template>
	</div>
} @else {
	<div class="price-loading skeleton-loading">
		<div class="skeleton-loading"></div>
	</div>
}

<ng-template #priceDetailslModal>
	<cramo-modal [title]="appData().product?.pricingDetails" (byClose)="closePriceDetailsModal()">
		<div modal-body>
			<div class="product-preview">
				@if (product?.ImageUrl) {
					<div class="product-preview-image">
						<img [src]="product.ImageUrl + '?width=120'" loading="lazy" alt="" />
					</div>
				}

				<div class="product-preview-text">
					<div class="product-preview-title">{{ product?.Name }}</div>
					<div class="product-preview-brand">
						{{ product?.Properties?.General?.BrandTypeDisplayBrand?.Value }}
						{{ product?.Properties?.General?.BrandTypeType?.Value }}
					</div>
				</div>
			</div>
			<div class="price-list">
				@for (productPriceInfo of prices(); track prices()) {
					<div class="price-list-item">
						@if (productPriceInfo.PurchaseType === 'Sale') {
							<div class="price-list-title">{{ productPriceInfo.Packaging.PackagingName | unitTranslation }}</div>
						} @else {
							<div class="price-list-title">{{ productPriceInfo.TimeUnit | unitTranslation }}</div>
						}
						<div class="price-list-prices">
							<table>
								<tr>
									<td>{{ appData().product?.priceExcludingVAT }}</td>
									<td>{{ productPriceInfo.Price | number: '1.2-2' }} {{ currency }}</td>
								</tr>
								<tr>
									<td>{{ appData().product?.vat }}</td>
									<td>{{ productPriceInfo.PriceExclVat | number: '1.2-2' }} {{ currency }}</td>
								</tr>
								@if (
									featureToggles().insurance.isInsuranceInformationForProductsEnabled &&
									productPriceInfo.InsuranceInPercent > 0
								) {
									<tr>
										<td>
											{{ appData().product?.insurance }}
											@if (featureToggles().insurance.isInsurancePercentageForProductsEnabled) {
												({{ productPriceInfo.InsuranceInPercent | number: '1.0-1' }}%)
											}
										</td>
										<td>
											{{ productPriceInfo.InsuranceAmountExcludingVatPerTimeUnit | number: '1.2-2' }}
											{{ currency }}
										</td>
									</tr>
									<tr>
										<td>{{ appData().product?.vatOnInsurance }}</td>
										<td>
											{{ productPriceInfo.InsuranceVatAmountPerTimeUnit | number: '1.2-2' }}
											{{ currency }}
										</td>
									</tr>
								}

								<tr>
									<td>{{ appData().product?.priceIncludingVAT }}</td>
									<td>
										{{
											productPriceInfo.PriceInclVat +
												(featureToggles().insurance.isInsuranceInformationForProductsEnabled
													? productPriceInfo.InsuranceAmountExcludingVatPerTimeUnit +
														productPriceInfo.InsuranceVatAmountPerTimeUnit
													: 0) | number: '1.2-2'
										}}
										{{ currency }}
									</td>
								</tr>
							</table>
						</div>
					</div>
				}
			</div>
		</div>
	</cramo-modal>
</ng-template>
