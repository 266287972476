import { Component, Input, OnDestroy, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import moment, { Moment } from 'moment-mini';
import { map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AvailabilityUtilsService } from '../../services/availability-utils.service';
import { CartOldService } from '../../services/cart/cart-old.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DepotService } from '../../services/depot/depot.service';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { PriceUtilsService } from '../../services/price-utils.service';
import { ProductUtilsService } from '../../services/product-utils.service';
import { ProductService } from '../../services/product.service';
import { PunchoutService } from '../../services/punchout.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user/user.service';
import { Depot } from '../../types/depot.types';
import { Jobsite } from '../../types/jobsite.types';
import { CartOrder, CartStore } from '../../types/order.types';
import { AvailabilityResponseDto, Product, RegionAvailabilityRequest } from '../../types/product.types';
import { User } from '../../types/user.types';
import { DeliveryType, PickupType } from '../../utils/constants';
import { formatDate } from '../../utils/date';
import { AddEditCartBase, CART_STORAGE } from './add-edit-cart-base-old';

@Component({
	selector: 'cramo-edit-cart',
	templateUrl: './add-edit-cart-item-old.component.html',
	styleUrls: ['./add-edit-cart-item-old.component.scss'],
})
export class EditCartComponent extends AddEditCartBase implements OnInit, OnDestroy {
	constructor(
		epiService: EpiService,
		configurationService: ConfigurationService,
		productUtil: ProductUtilsService,
		productService: ProductService,
		priceUtils: PriceUtilsService,
		marketService: MarketService,
		depotService: DepotService,
		userService: UserService,
		cartService: CartOldService,
		punchoutService: PunchoutService,
		protected availabilityUtils: AvailabilityUtilsService,
		private authService: AuthService,
		private storageService: StorageService,
	) {
		super(
			epiService,
			configurationService,
			productUtil,
			productService,
			priceUtils,
			marketService,
			depotService,
			userService,
			punchoutService,
			cartService,
		);
		this.isEditCartComponent = true;
		this.depotService.currentDepot$.subscribe((depot) => {
			this.currentDepot = depot;
		});

		this.logisticsRules = this.epiService.logisticsRules;
		this.configurations = this.configurationService.configurations;
		this.canRent =
			this.userService.hasPermissionSync('RENT_TRANSPORT') ||
			this.userService.hasPermissionSync('RENT_PICKUP') ||
			this.userService.hasPermissionSync('RENT_BOX_PICKUP');
		this.canOnlyRentPickup =
			this.userService.hasPermissionSync('RENT_PICKUP') &&
			!this.userService.hasPermissionSync('RENT_TRANSPORT') &&
			!this.userService.hasPermissionSync('RENT_BOX_PICKUP');
		this.isOrder = true;

		this.user = toSignal(this.userService.user$);
		this.userService.user$.pipe(takeUntil(this.onDestroy$)).subscribe((user: User) => {
			if (user) {
				this.canRent =
					this.userService.hasPermissionSync('RENT_TRANSPORT') ||
					this.userService.hasPermissionSync('RENT_PICKUP') ||
					this.userService.hasPermissionSync('RENT_BOX_PICKUP');
				this.canOnlyRentPickup =
					this.userService.hasPermissionSync('RENT_PICKUP') &&
					!this.userService.hasPermissionSync('RENT_TRANSPORT') &&
					!this.userService.hasPermissionSync('RENT_BOX_PICKUP');
				this.updateComponentState();
			}
		});
		this.updateComponentState();
	}

	public addingToCart = false;
	public isLoading = false;

	private user: Signal<User>;
	private order: CartOrder;
	private orderJobsite: Jobsite;
	private isOrderInit = true;
	private savedOrderDeliveryDate = '';
	private deferredGetProductAvailability = false;

	@Input()
	public set setOrder(value: { order: CartOrder; jobsite: Jobsite }) {
		this.order = { ...value.order };
		this.orderJobsite = value.jobsite;
		this.selectedQuantity = this.order.Quantity;
		this.selectedUnit = this.order.Unit;
		this.selectedPickupType = value.order.PickupType;

		this.depotService
			.getDepotById(this.order.DepotId)
			.pipe(take(1))
			.subscribe((depot) => {
				this.currentDepot = depot;
			});
	}

	ngOnInit(): void {
		this.holidays = this.configurations.calendarHolidays;

		this.productService
			.getProductById(this.order.ProductId)
			.pipe(take(1), takeUntil(this.onDestroy$))
			.subscribe((product: Product) => {
				this.product = { ...product, Id: this.order.ProductId };
				this.updateComponentState();
				this.getProductPrice();
				if (this.deferredGetProductAvailability) {
					// User opened flip panel before product was loaded
					this.getProductAvailability();
					this.deferredGetProductAvailability = false;
				}
			});

		this.depotFilter = this.order.PickupType === PickupType.BOX ? 'box' : 'none';
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	private initOrder() {
		// set jobsites
		this.selectedJobsite = this.orderJobsite;
		this.jobsiteSelectComponent?.setJobsite(this.orderJobsite);
		// set delivery type
		this.configurationService.featureToggles$.pipe(take(1)).subscribe((featureToggles) => {
			const selectedDeliveryType = featureToggles.order.isPickupDefaultDelivery
				? DeliveryType.PICKUP
				: DeliveryType.TRANSPORT;
			this.selectedDeliveryType.set(selectedDeliveryType);
		});
		this.selectedUnit = this.order.Unit;

		this.savedOrderDeliveryDate = this.order.DeliveryDate;

		if (this.product) {
			this.getProductAvailability(); // Needs this.product to be loaded
		} else {
			this.deferredGetProductAvailability = true;
		}
	}

	public override cancel() {
		this.order.DeliveryDate = this.savedOrderDeliveryDate;
		this.setOrderDates();
		this.setOrderTimes();

		this.selectedQuantity = this.order.Quantity;
		super.cancel();
	}

	public onDepotSelected(depotInfo: { availability: number; depot: Depot }) {
		const { depot, availability } = depotInfo;
		this.currentDepot = depot;
		this.currentDepotAvailability = availability;
		this.productAvailability = availability;
		this.parseAvailability();
		this.isLoading = false;
	}

	public onStartDateChange(date: Moment) {
		this.selectedTime = '';
		this.order.DeliveryDate = date.format('YYYY-MM-DDTHH:mm:ssZ');
		this.calculatedAvailability = this.availabilityUtils.calculateAvailability({
			deliveryType: this.selectedDeliveryType(),
			pickupType: this.selectedPickupType,
			logisticsRules: this.epiService.logisticsRules,
			configurations: this.configurationService.configurations,
			availability: this.currentDepotAvailability,
			quantityAvailableAtDeliveryUnit: this.quantityAvailableAtDeliveryUnit,
			quantityAvailableInDeliveryArea: this.quantityAvailableInDeliveryArea,
			date,
			market: this.marketService.currentMarket,
			pickupOnSaturdays: this.checkDepotRuleForSaturdays(),
		});

		this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
		this.createCalendarOptions(
			date,
			this.startCalendar.endDate,
			this.calculatedAvailability.firstAvailableDate,
			this.holidays,
		);
		this.stepperOptions = { max: this.calculatedAvailability.availability, min: 1, value: this.selectedQuantity };
		this.isOrderDateValid = this.calculatedAvailability.firstAvailableDate.isSameOrBefore(
			moment(this.order.DeliveryDate),
			'days',
		);
		this.updateComponentState();
	}

	private setOrderDates() {
		this.createCalendarOptions(
			moment(this.order.DeliveryDate),
			moment(this.order.ReturnDate),
			this.calculatedAvailability.firstAvailableDate,
			this.holidays,
		);
	}

	private setOrderTimes() {
		this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
		this.selectedTime = this.order.DeliveryTime ? this.order.DeliveryTime : null;
		if (this.selectedTime === ' - ') {
			this.selectedTime = null;
		}
	}

	private setOrderQuantity() {
		this.setStepperOptions(this.order.Quantity);
	}

	public onFlipPanelOpen() {
		this.setSelectedDeliveryType();
		setTimeout(() => {
			this.initOrder();
		}, 0);
	}

	public getProductAvailability(): void {
		this.runAvailability();
	}

	private runAvailability() {
		this.checkingProductAvailability = true;
		const date = this.availabilityUtils.addWeekDays(moment(), 2, this.holidays);
		const item = this.availabilityUtils.createAvailabilityCall(this.product, date, this.currentDepot);

		this.updatingOrder = true;

		const { UseEarliestPossibleDeliveryDay } = this.epiService.logisticsRules.LogisticRules;
		const isTransportDelivery = this.selectedDeliveryType() === DeliveryType.TRANSPORT;

		const availability: RegionAvailabilityRequest = {
			IsTransportDelivery: isTransportDelivery && UseEarliestPossibleDeliveryDay,
			AvailabilityRequestModels: [item],
			JobsiteId: this.selectedJobsite?.Id,
			CategoryId: this.product?.CategoryId,
			Language: this.marketService.currentLanguage,
		};

		this.productService
			.getRegionAvailability(availability)
			.pipe(take(1), takeUntil(this.onDestroy$))
			.subscribe((availability: AvailabilityResponseDto[]) => {
				this.productAvailability = availability[0].TotalQuantityPossibleAvailableNoReturn;
				this.currentDepotAvailability = availability[0].TotalQuantityPossibleAvailableNoReturn;
				this.quantityAvailableAtDeliveryUnit = availability[0].QuantityAvailableAtDeliveryUnit;
				this.quantityAvailableInDeliveryArea = availability[0].QuantityAvailableInDeliveryArea;
				this.productAvailabilityResponse = availability[0];
				this.parseAvailability();
				this.checkingProductAvailability = false;
				this.updatingOrder = false;
			});
	}

	private parseAvailability() {
		this.calculatedAvailability = this.availabilityUtils.calculateAvailability({
			deliveryType: this.selectedDeliveryType(),
			pickupType: this.selectedPickupType,
			logisticsRules: this.epiService.logisticsRules,
			configurations: this.configurationService.configurations,
			availability: this.currentDepotAvailability,
			quantityAvailableAtDeliveryUnit: this.quantityAvailableAtDeliveryUnit,
			quantityAvailableInDeliveryArea: this.quantityAvailableInDeliveryArea,
			date: moment(this.order.DeliveryDate),
			market: this.marketService.currentMarket,
			pickupOnSaturdays: this.checkDepotRuleForSaturdays(),
		});

		this.productAvailability = this.calculatedAvailability.availability;

		if (this.isOrderInit) {
			this.validateInit();
			this.isOrderInit = false;
		} else {
			const endDate = this.endCalendar?.endDate?.isSameOrAfter(this.calculatedAvailability.selectedDate)
				? this.endCalendar.endDate
				: null;

			let selectedDeliveryDate = this.calculatedAvailability.selectedDate;
			if (
				this.selectedDeliveryType() === DeliveryType.TRANSPORT &&
				this.availabilityUtils.isWeekend(moment(this.order.DeliveryDate))
			) {
				selectedDeliveryDate = this.calculatedAvailability.firstAvailableDate;
			}

			this.createCalendarOptions(
				selectedDeliveryDate,
				endDate,
				this.calculatedAvailability.firstAvailableDate,
				this.holidays,
			);
			this.setStepperOptions();
			this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
			this.updateComponentState();
		}
	}

	private validateInit() {
		this.setOrderDates();
		this.setOrderQuantity();
		this.setOrderTimes();

		this.updateComponentState();

		this.isOrderDateValid = this.calculatedAvailability.firstAvailableDate.isSameOrBefore(
			moment(this.order.DeliveryDate),
			'days',
		);
	}

	private getStorageKey() {
		return `${CART_STORAGE}_${this.user().IdpUserId}_${this.user().CustomerInfo.CustomerId}`;
	}

	private addOrderToCart() {
		if (!this.isLoggedIn()) {
			this.authService.login();
			return;
		}

		this.addingToCart = true;
		const logisticHandlingCode = this.product.Properties.AverageDimension?.BrandTypeLogisticHandlingCode?.Value ?? null;
		const cartToStore: CartStore = {
			Jobsite: this.selectedJobsite,
			OrderRow: {
				DeliveryType: this.selectedDeliveryType(),
				PickupType: this.selectedPickupType,
				DeliveryDate: this.startCalendar.startDate?.format('YYYY-MM-DDTHH:mm:ssZ'),
				DeliveryTime: this.selectedDeliveryType() === DeliveryType.TRANSPORT ? this.selectedTime : '',
				ReturnDate: this.endCalendar.endDate?.format('YYYY-MM-DD'),
				Quantity: this.selectedQuantity,
				Category: this.product.Category,
				LogisticHandlingCode: logisticHandlingCode,
				WeightKG: this.product.WeightKG,
				Unit: this.selectedUnit,
			},
		};

		this.storageService.setItem(this.getStorageKey(), cartToStore);

		this.cartService
			.addToCart(
				this.selectedJobsite.Id,
				this.selectedDeliveryType(),
				this.selectedPickupType,
				this.startCalendar.startDate,
				this.product.Id,
				this.selectedQuantity,
				this.endCalendar.endDate,
				this.currentDepot.Id,
				this.selectedDeliveryType() === this.deliveryType.TRANSPORT ? this.selectedTime : '',
				this.product.CategoryId,
				logisticHandlingCode,
				this.marketService.currentMarket,
				this.product.WeightKG,
				this.selectedUnit,
			)
			.subscribe(() => {
				this.addingToCart = false;
				this.closePanel.emit(true);
			});
	}

	private getProductPrice() {
		if (this.isLoggedIn()) {
			this.productService
				.getProductPrices({
					marketId: this.marketService.currentMarket,
					itemNumber: this.product.Properties.General.BrandTypeItemNumber.Value,
					deliveryDate: formatDate(new Date(), 'YYYY-MM-DD'),
					purchaseType: this.isMerchandise ? 'Sale' : 'Rent',
					isAnonymous: false,
				})
				.subscribe((prices) => {
					this.pricesArray = prices;
					this.updateComponentState();
				});
		}
	}

	public override saveOrder() {
		const orderUpdate: CartOrder = {
			DeliveryDate: this.startCalendar.startDate.toISOString(),
			DeliveryType: this.selectedDeliveryType(),
			PickupType: this.selectedPickupType,
			DeliveryTime: this.selectedTime,
			DepotId: this.currentDepot.Id,
			LineItemId: this.order.LineItemId,
			LogisticHandlingCode: this.order.LogisticHandlingCode,
			ProductCategory: this.order.ProductCategory,
			ProductId: this.order.ProductId,
			Quantity: this.selectedQuantity,
			ReturnDate: this.endCalendar.endDate?.isValid() ? this.endCalendar.endDate.format('YYYY-MM-DD') : null,
			ReturnType: this.order.ReturnType,
			WeightKG: this.order.WeightKG,
			Unit: this.selectedUnit,
			BrandType: null,
			BrandTypeItemNumber: null,
			DisplayName: null,
			ImageSrc: null,
			LinkUrl: null,
		};

		this.updatingOrder = true;

		if (this.orderJobsite.Id !== this.selectedJobsite.Id) {
			this.cartService
				.deleteOrder(this.order.LineItemId)
				.pipe(
					map(() => this.addOrderToCart()),
					take(1),
					takeUntil(this.onDestroy$),
				)
				.subscribe(() => {
					this.closePanel.emit(true);
					setTimeout(() => {
						this.updatingOrder = false;
					}, 10);
				});
		} else {
			this.updateOrder(orderUpdate);
		}
	}

	private updateOrder(order: CartOrder) {
		this.cartService
			.updateOrder(order)
			.pipe(take(1), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.closePanel.emit(true);
				this.updatingOrder = false;
			});
	}
}
