import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DepotService } from '../../services/depot/depot.service';
import { LogisticsRulesDto } from '../../services/epi/epi.model';
import { EpiService } from '../../services/epi/epi.service';
import { Depot } from '../../types/depot.types';
import { AvailabilityResponseDto } from '../../types/product.types';
import { AppData } from '../../types/translations.types';

@Component({
	selector: 'cramo-product-availability',
	templateUrl: './product-availability.component.html',
	styleUrls: ['./product-availability.component.scss'],
})
export class ProductAvailabilityComponent implements OnInit, OnChanges {
	constructor(
		private epiService: EpiService,
		private depotService: DepotService,
	) {}

	@Input() public availability: AvailabilityResponseDto;
	public appData: AppData;
	public logisticsRules: LogisticsRulesDto;
	public currentDepot: Depot;
	public currentDepotAvailability: number;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['availability'].currentValue) {
			this.setDepotAvailability();
		}
	}

	ngOnInit() {
		this.appData = this.epiService.appData;
		this.logisticsRules = this.epiService.logisticsRules;
		this.depotService.currentDepot$.subscribe((depot) => {
			this.currentDepot = depot;
		});
		this.setDepotAvailability();
	}

	private setDepotAvailability() {
		this.currentDepotAvailability = this.availability?.Depots.find(
			(x) => x.Depot === this.currentDepot?.Id,
		)?.QuantityPossibleAvailableNoReturn;
	}
}
