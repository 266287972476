import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, of, shareReplay, switchMap, take, tap, throwError } from 'rxjs';
import { CreditControlInformationComponent } from '../components/credit-control-information/credit-control-information-component';
import { ConfigurationService } from './configuration.service';
import { UserService } from './user/user.service';

@Injectable({
	providedIn: 'root',
})
export class CreditCheckService {
	private httpClient = inject(HttpClient);
	private configurationService = inject(ConfigurationService);
	private userService = inject(UserService);
	private matDialog = inject(MatDialog);

	/**
	 *  Should be possible to make private when old cart is removed since {@link runCreditCheck} can be subscribed to instead
	 */
	public readonly isCreditApproved$: Observable<boolean> = combineLatest([
		this.configurationService.featureToggles$,
		this.userService.isLoggedIn$,
	]).pipe(
		switchMap(([featureToggles, isUserAuthenticated]) => {
			if (!isUserAuthenticated) {
				return of(false);
			}

			if (!featureToggles.other.isCreditCheckEnabled) {
				return of(true);
			}

			return this.getUserCreditCheck();
		}),
		shareReplay(1),
	);

	/**
	 * Performs a credit check using the {@link isCreditApproved$} observable. If the credit is approved,
	 * the method completes silently. If not, it throws an error and opens an informational modal to
	 * notify the user.
	 */
	public runCreditCheck(): Observable<void> {
		const creditNotApprovedErrorMessage = 'CREDIT_NOT_APPROVED';

		return this.isCreditApproved$.pipe(
			take(1),
			switchMap((isCreditApproved) => {
				if (isCreditApproved) {
					return of(null);
				}
				return throwError(() => new Error(creditNotApprovedErrorMessage));
			}),
			tap({
				error: (error) => {
					if (error?.message === creditNotApprovedErrorMessage) {
						this.matDialog.open(CreditControlInformationComponent);
					}
				},
			}),
		);
	}

	private getUserCreditCheck() {
		return this.httpClient.get<boolean>(`/webapi/auth/user/creditcheck`);
	}
}
