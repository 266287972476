import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
	AfterContentInit,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmDisableJobsiteComponent } from '../../pages/jobsite-page/confirm-disable-jobsite/confirm-disable-jobsite.component';
import { AddToCartComponent } from '../add-edit-cart-item-old/add-to-cart-old.component';
import { EditCartComponent } from '../add-edit-cart-item-old/edit-cart-old.component';
import { CommonElementsModule } from '../common-elements.module';
import { ReturnOrderComponent } from '../return-order/return-order.component';

@Component({
	selector: 'cramo-flip-panel-content',
	template: '',
	standalone: true,
})
export class FlipPanelContentComponent {
	@Output() public closePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
}

enum DeviceSize {
	Small = 'Small',
	Large = 'Large',
}

@Component({
	selector: 'cramo-flip-panel',
	templateUrl: './flip-panel.component.html',
	styleUrls: ['./flip-panel.component.scss'],
	standalone: true,
	imports: [CommonElementsModule, FlipPanelContentComponent],
	animations: [
		trigger('cardVisibility', [
			transition(':enter', [
				style({ transform: 'rotate3d(1,0,0,-90deg)' }),
				animate('450ms cubic-bezier(0.545, 0.310, 0.100, 1.615)'),
			]),
			transition(':leave', animate('200ms ease-in-out', style({ transform: 'rotate3d(1,0,0,-90deg)' }))),
		]),
	],
})
export class FlipPanelComponent implements OnInit, AfterContentInit, OnDestroy {
	constructor(
		private renderer: Renderer2,
		private breakpointObserver: BreakpointObserver,
		private dialog: MatDialog,
	) {}

	@ContentChild(EditCartComponent) private editCart: EditCartComponent;
	@ContentChild(AddToCartComponent) private addToCart: AddToCartComponent;
	@ContentChild(ConfirmDisableJobsiteComponent) private disableJobsite: ConfirmDisableJobsiteComponent;

	@ContentChild(ReturnOrderComponent) private returnOrderContentChild: ReturnOrderComponent;

	@ViewChild('flipCardBody') private flipCardBody: ElementRef<HTMLElement>;
	@ViewChild('flipCardTrigger') private flipCardTrigger: ElementRef<HTMLElement>;
	@ViewChild('flipPanelModal', { static: true }) private flipPanelModaltemplate: TemplateRef<never>;

	public showCardFlag = false;
	public bringToFront = false;
	public isModalOpen = false;

	private windowWidth: number;
	private windowHeight: number;

	private keyboardListener: () => void;

	private dialogSubscription = this.breakpointObserver.observe([
		Breakpoints.Handset,
		Breakpoints.Tablet,
		Breakpoints.Web,
	]);
	private onDestroy$ = new ReplaySubject(1);
	private currentScreenSize = DeviceSize.Large;
	private dialogModalRef: MatDialogRef<TemplateRef<never>>;

	@Input() public minWidth = 0;
	@Input() public alignLeft = true;
	@Input() public disabled = false;
	@Input() public overrideFlip = false;

	ngOnInit() {
		this.monitorBreakpoints();
		this.windowWidth = window.innerWidth;
		this.windowHeight = window.innerHeight;
	}

	ngAfterContentInit() {
		if (this.editCart) {
			this.editCart.closePanel.subscribe(() => {
				this.showCardFlag = false;
				this.closeModal();
			});
		}
		if (this.addToCart) {
			this.addToCart.closePanel.subscribe(() => {
				this.showCardFlag = false;
				this.closeModal();
			});
			this.addToCart.modalStateChanged.subscribe((isOpen: boolean) => {
				this.isModalOpen = isOpen;
			});
		}
		if (this.disableJobsite) {
			this.disableJobsite.closePanel.subscribe(() => {
				this.showCardFlag = false;
				this.closeModal();
			});
		}
		if (this.returnOrderContentChild) {
			this.returnOrderContentChild.closePanel.subscribe(() => {
				this.showCardFlag = false;
			});
		}
	}

	public open(): void {
		if (this.currentScreenSize === DeviceSize.Large) {
			this.showCard();
		} else {
			this.openModal();
		}
	}

	private showCard() {
		if (!this.disabled && !this.overrideFlip) {
			this.showCardFlag = !this.showCardFlag;

			if (this.showCardFlag) {
				this.bringToFront = true;
			}

			if (this.editCart) {
				this.editCart.onFlipPanelOpen();
				setTimeout(() => {
					this.checkYAxisPosition();
					this.checkXAxisPosition();
				}, 1);
			}
			if (this.addToCart) {
				this.addToCart.onFlipPanelOpen();
				setTimeout(() => {
					this.checkYAxisPosition();
					this.checkXAxisPosition();
				}, 1);
			}
		}

		if (this.showCardFlag === true) {
			this.keyboardListener = this.renderer.listen('document', 'keyup', (keyboardEvent: KeyboardEvent) => {
				if (keyboardEvent.key === 'Escape') {
					this.closeCard();
				}
			});
		}
	}

	private checkYAxisPosition() {
		const bodyElement = this.flipCardBody.nativeElement;
		const bodyElementHeight = bodyElement.offsetHeight;

		const triggerElement = this.flipCardTrigger.nativeElement;
		const triggerElementLeftCornerYTopPos = triggerElement.getBoundingClientRect().top;

		const bodyYPosBottomSide = triggerElementLeftCornerYTopPos + bodyElementHeight;

		if (bodyYPosBottomSide > this.windowHeight) {
			const topAdjustment = bodyYPosBottomSide - this.windowHeight + 20;
			this.renderer.setStyle(bodyElement, 'top', `${-topAdjustment}px`);
		}
	}

	private checkXAxisPosition() {
		const bodyElement = this.flipCardBody.nativeElement;
		const bodyElementWidth = bodyElement.offsetWidth;

		const triggerElement = this.flipCardTrigger.nativeElement;
		const triggerElementLeftCornerXPos = triggerElement.getBoundingClientRect().left;
		const triggerElementRightCornerXPos = triggerElement.getBoundingClientRect().right;

		const bodyXPosRightSide = triggerElementLeftCornerXPos + bodyElementWidth;
		const bodyXPosLeftSide = triggerElementRightCornerXPos - bodyElementWidth;

		if (this.alignLeft && bodyXPosRightSide > this.windowWidth) {
			const leftAdjustment = bodyXPosRightSide - this.windowWidth + 30;
			this.renderer.setStyle(bodyElement, 'left', `${-leftAdjustment}px`);
		} else if (!this.alignLeft && bodyXPosLeftSide < 0) {
			const rightAdjustment = bodyXPosLeftSide - 20;
			this.renderer.setStyle(bodyElement, 'right', `${rightAdjustment}px`);
		}
	}

	public closeCard() {
		setTimeout(() => {
			this.showCardFlag = false;
			if (this.keyboardListener) {
				this.keyboardListener();
			}
		}, 0);
	}

	public animationDone() {
		if (!this.showCardFlag) {
			this.bringToFront = false;
		}
	}

	private monitorBreakpoints(): void {
		this.dialogSubscription.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
			this.currentScreenSize =
				this.breakpointObserver.isMatched(Breakpoints.Handset) || this.breakpointObserver.isMatched(Breakpoints.Tablet)
					? DeviceSize.Small
					: DeviceSize.Large;

			if (this.currentScreenSize === DeviceSize.Large) {
				this.closeModal();
			} else {
				this.closeCard();
			}

			this.windowWidth = window.innerWidth || document.body.clientWidth;
			this.windowHeight = window.innerHeight || document.body.clientHeight;
		});
	}

	public closeModal() {
		if (this.dialogModalRef) {
			this.dialogModalRef.close();
		}
	}

	private openModal() {
		this.dialogModalRef = this.dialog.open(this.flipPanelModaltemplate, {
			position: { bottom: '0px' },
			maxWidth: '100%',
			minWidth: '80vw',
			autoFocus: true,
		});

		this.dialogModalRef
			.afterOpened()
			.pipe(take(1))
			.subscribe(() => {
				if (this.editCart) {
					this.editCart.onFlipPanelOpen();
				}
				if (this.addToCart) {
					this.addToCart.onFlipPanelOpen();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.keyboardListener) {
			this.keyboardListener();
		}
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
