import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EpiService } from '../../../services/epi/epi.service';
import { AppData } from '../../../types/translations.types';

@Component({
	selector: 'cramo-confirm-disable-jobsite',
	templateUrl: './confirm-disable-jobsite.component.html',
})
export class ConfirmDisableJobsiteComponent {
	public appData: AppData;

	@Input() public disablingJobsite: boolean;

	@Output() public byDisableTheJobsite: EventEmitter<null> = new EventEmitter();
	@Output() public closePanel: EventEmitter<boolean> = new EventEmitter();

	constructor(private epiService: EpiService) {
		this.appData = this.epiService.appData;
	}

	public disableTheJosite() {
		this.closePanel.emit(true);
		this.byDisableTheJobsite.emit();
	}
}
