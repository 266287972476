import { Component, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EpiService } from '../../services/epi/epi.service';
import { AppData } from '../../types/translations.types';
import { ButtonComponent } from '../button/button.component';
import { CommonElementsModule } from '../common-elements.module';
@Component({
	selector: 'cramo-credit-control-information-component',
	standalone: true,
	imports: [CommonElementsModule, MatDialogModule, ButtonComponent],
	templateUrl: './credit-control-information-component.html',
})
export class CreditControlInformationComponent {
	public error = false;
	public isFetchingExport = false;
	public showLookupMessage = false;
	public appData: Signal<AppData>;
	public existingJobsiteName: string;
	public creditControlRichText: SafeHtml;

	public dialogData = inject(MAT_DIALOG_DATA);
	public dialogRef = inject(MatDialogRef<CreditControlInformationComponent>);
	private sanitizer = inject(DomSanitizer);
	private epiService = inject(EpiService);
	constructor() {
		this.appData = toSignal(this.epiService.appData$);
		this.creditControlRichText = this.sanitizer.bypassSecurityTrustHtml(this.appData().global.creditControlModalText);
	}

	public closeError() {
		this.showLookupMessage = false;
		this.error = false;
	}

	public closeModal(value: boolean) {
		this.dialogRef.close(value);
	}
}
