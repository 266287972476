import {
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	inject,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EpiService } from '../../services/epi/epi.service';
import { JobsiteService } from '../../services/jobsite/jobsite.service';
import { UserService } from '../../services/user/user.service';
import { Jobsite } from '../../types/jobsite.types';
import { User } from '../../types/user.types';
import {
	CreateJobsiteModalComponent,
	CreateJobsiteModalData,
} from '../create-jobsite-modal/create-jobsite-modal.component';
import { SearchSelectLocalizedLabels } from './localization.model';

@Component({
	selector: 'cramo-search-select',
	templateUrl: './search-select.component.html',
	styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent implements OnInit, OnDestroy {
	public canAddJobsite = false;
	public customerId = '';
	public marketId = '';
	public isAddingJobsite = false;
	public isListVisible = false;
	public jobsites: Jobsite[];
	public localizedLabels: SearchSelectLocalizedLabels;
	public onDestroy$ = new Subject();
	public selectedOption: Jobsite;
	public selectedJobsites: Jobsite[] = [];
	public searchString = '';
	public isLoadingJobsites = true;
	public appData = toSignal(this.epiService.appData$);

	@Input() public multiSelectMode = false;
	@Input() public largeSize = false;
	@Input() public elementId = 'jobsite-selector';
	@Input() public showSelectedState = false;
	@Input() public disabled = false;

	@Input() private filterJobsiteId = '';

	@Output() public bySelect: EventEmitter<Jobsite[] | Jobsite> = new EventEmitter();
	@Output() public byChangeModalState: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('jobsiteSelector') private jobsiteSelector: ElementRef<HTMLElement>;

	private destroyRef = inject(DestroyRef);

	constructor(
		private dialog: MatDialog,
		private epiService: EpiService,
		private jobsiteService: JobsiteService,
		private userService: UserService,
	) {
		this.clearSelectedJobsite();
		this.localizedLabels = new SearchSelectLocalizedLabels(this.epiService.appData);

		this.userService.user$.pipe(takeUntilDestroyed()).subscribe((user: User) => {
			this.canAddJobsite = this.userService.hasPermissionSync('CREATE_JOB_SITES');
			if (user) {
				this.customerId = user.CustomerInfo.CustomerId;
				this.marketId = user.CustomerInfo.MarketId;
			}
		});
	}

	public filterFunction(item: Jobsite, trigger: string) {
		if (item && trigger) {
			return item.Name.toLowerCase().includes(trigger.toLowerCase());
		} else {
			return true;
		}
	}

	ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	ngOnInit() {
		this.jobsiteService.jobsites$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.isLoadingJobsites = false;
			if (this.marketId) {
				this.getAndFilterJobsiteAndFavourites(null);
			}
		});
	}

	private getAndFilterJobsiteAndFavourites(jobsite: Jobsite | null) {
		this.jobsiteService.jobsites$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((jobsites) => {
			if (!jobsites) return;

			if (jobsite) {
				const jobsiteToSelect = jobsites.find((js) => js.Id === jobsite.Id);
				if (jobsiteToSelect) {
					this.selectedOption = jobsiteToSelect;
					this.isListVisible = false;
					this.bySelect.emit(jobsiteToSelect);
				}
			}

			this.jobsites = jobsites.filter((item) => item.Id !== this.filterJobsiteId);
		});
	}

	public openCreateJobsiteModal(): void {
		this.dialog.open<CreateJobsiteModalComponent, CreateJobsiteModalData>(CreateJobsiteModalComponent, {
			width: '500px',
			data: {
				isFavoriteCheckboxEnabled: true,
			},
		});
	}
	public selectJobsite(value: Jobsite) {
		if (this.multiSelectMode) {
			const jobsiteSelectedIndex = this.selectedJobsites.findIndex((x) => x.Id === value.Id);
			if (jobsiteSelectedIndex > -1) {
				this.selectedJobsites.splice(jobsiteSelectedIndex, 1);
			} else {
				this.selectedJobsites.push(value);
			}
			this.selectedOption = this.selectedJobsites.length === 0 ? null : this.selectedJobsites[0];
			this.bySelect.emit(this.selectedJobsites);
		} else {
			this.selectedOption = value;
			this.bySelect.emit(value);
			this.showList(false);
		}
	}

	public selectAllJobsites() {
		this.selectedJobsites = this.searchString
			? this.jobsites.filter((js) => js.Name.toLowerCase().includes(this.searchString.toLowerCase()))
			: this.jobsites;

		this.bySelect.emit(this.selectedJobsites);
	}

	public clearSelectedJobsite() {
		this.selectedOption = null;
		this.selectedJobsites = [];
		this.bySelect.emit(this.selectedJobsites);
	}

	public setJobsite(jobsite: Jobsite) {
		this.selectedOption = jobsite;
	}

	public showList(show?: boolean) {
		if (!this.disabled) {
			setTimeout(() => {
				if (show === null) {
					this.isListVisible = !this.isListVisible;
				} else {
					this.isListVisible = show;
				}

				if (this.isListVisible === false) {
					this.searchString = null;
				} else if (document.activeElement !== this.jobsiteSelector.nativeElement) {
					this.jobsiteSelector.nativeElement.focus();
				}
			}, 0);
		}
	}

	public trackByFn(_: number, item: Jobsite) {
		return item.Id;
	}
}
