<ng-container *ngIf="appData$ | async as appData">
	<div
		class="depot-selector__container"
		[cramoClickOutside]="isListOpen"
		(byOutsideClicked)="hideList()"
		data-test-id="depot-selector"
	>
		<input
			type="text"
			class="form-control depot-selector"
			[ngClass]="{ 'depot-selector--selected': selectedDepot }"
			[id]="elementId"
			(focus)="openList()"
			[placeholder]="appData.addToCart.selectDepotInputPlaceholder"
			[value]="
				disabled || (filter === 'box' && !boxDepots.length)
					? appData.addToCart.selectDepotInputPlaceholder
					: selectedDepot?.Name
			"
			readonly
			[disabled]="disabled || isLoadingAvailability || (filter === 'box' && !boxDepots.length)"
		/>
		<div class="depot-selector__icon">
			@if (isLoadingAvailability) {
				<cramo-icon iconName="spinner" />
			} @else {
				<cramo-icon iconName="location_on" />
			}
		</div>

		@if (!isLoadingAvailability && selectedDepot !== null) {
			<cramo-depot-availability-badge
				class="depot-selector__badge"
				[value]="selectedAvailability - (depotCartProductQuantities()[selectedDepot.Id]?.[productNumber] ?? 0)"
			/>
		}

		@if (isListOpen && (filter === 'none' ? allDepots.length : boxDepots.length)) {
			<div class="depot-select-dropdown">
				<div class="depot-select-content">
					<ng-container
						[ngTemplateOutlet]="displayDepots"
						[ngTemplateOutletContext]="{
							depots: filter === 'none' ? allDepots : boxDepots,
							openOnSaturdaysText: appData.depot.openOnSaturdaysText,
						}"
					/>
				</div>
			</div>
		}

		@if (filter === 'box' && !isLoadingAvailability && boxDepots.length === 0) {
			<cramo-message-box
				header="{{ appData.error.noBoxDepotsFoundHeader }}"
				message="{{ appData.error.noBoxDepotsFoundBody }}"
				level="warning"
			/>
		}
	</div>

	<ng-template #displayDepots let-depots="depots" let-openOnSaturdaysText="openOnSaturdaysText">
		<div
			*ngFor="let depot of depots; let i = index"
			(click)="selectDepot(depot.Depot, depot.QuantityPossibleAvailableNoReturn)"
			class="depot-item cursor frow-between-center"
			[ngClass]="{ selected: depot.Depot === selectedDepot?.Id }"
			attr.data-test-id="depot-option-{{ i }}"
		>
			<div>{{ depotMap[depot.Depot].Name }}</div>
			<div class="depot-selector-illustration-wrapper">
				@if (isOpenOnSaturdays(depotMap[depot.Depot])) {
					<cramo-icon iconName="info" [size]="16" title="{{ openOnSaturdaysText }}" />
				}

				<cramo-depot-availability-badge
					[value]="
						depot.QuantityPossibleAvailableNoReturn -
						(depotCartProductQuantities()[depotMap[depot.Depot].Id]?.[productNumber] ?? 0)
					"
				/>
			</div>
		</div>
	</ng-template>
</ng-container>
