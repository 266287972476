import { AppData } from '../../types/translations.types';

export class SearchSelectLocalizedLabels {
	public addJobsite: string;
	public chooseJobsite: string;
	public create: string;
	public find: string;
	public favoriteJobsites: string;
	public otherJobsites: string;
	public loadingJobsites: string;
	public clear: string;
	public jobsitesSelected: string;
	public oneJobsitesSelected: string;
	public selectAll: string;
	public moreSelected: string;
	public noJobSitesInfo: string;
	public noJobSitesInfoNotAllowedToAdd: string;

	constructor(appData: AppData = <AppData>{}) {
		if (!appData) {
			appData = <AppData>{};
		}
		this.addJobsite = typeof appData.addToCart?.addJobSite !== 'undefined' ? appData.addToCart.addJobSite : '';
		this.chooseJobsite = typeof appData.cart?.chooseJobsite !== 'undefined' ? appData.cart.chooseJobsite : '';
		this.create =
			typeof appData.addToCart?.newJobsite !== 'undefined'
				? appData.addToCart.newJobsite
				: typeof appData.global?.create !== 'undefined'
					? appData.global.create
					: '';
		this.find =
			typeof appData.addToCart?.searchJobsite !== 'undefined'
				? appData.addToCart.searchJobsite
				: typeof appData.global?.find !== 'undefined'
					? appData.global.find
					: '';
		this.favoriteJobsites =
			typeof appData.addToCart?.favoriteJobsites !== 'undefined' ? appData.addToCart.favoriteJobsites : '';
		this.otherJobsites = typeof appData.addToCart?.otherJobsites !== 'undefined' ? appData.addToCart.otherJobsites : '';
		this.loadingJobsites =
			typeof appData.addToCart?.loadingJobsites !== 'undefined' ? appData.addToCart.loadingJobsites : '';
		this.clear = typeof appData.addToCart?.clear !== 'undefined' ? appData.addToCart.clear : '';
		this.jobsitesSelected =
			typeof appData.addToCart?.jobsitesSelected !== 'undefined' ? appData.addToCart.jobsitesSelected : '';
		this.oneJobsitesSelected =
			typeof appData.addToCart?.oneJobsitesSelected !== 'undefined' ? appData.addToCart.oneJobsitesSelected : '';
		this.selectAll = typeof appData.addToCart?.selectAll !== 'undefined' ? appData.addToCart.selectAll : '';
		this.moreSelected = typeof appData.addToCart?.moreSelected !== 'undefined' ? appData.addToCart.moreSelected : '';
		this.noJobSitesInfo =
			typeof appData.addToCart?.noJobSitesInfo !== 'undefined' ? appData.addToCart.noJobSitesInfo : '';
		this.noJobSitesInfoNotAllowedToAdd =
			typeof appData.addToCart?.noJobSitesInfoNotAllowedToAdd !== 'undefined'
				? appData.addToCart.noJobSitesInfoNotAllowedToAdd
				: '';
	}
}
