@if (IsSuccess === false) {
	<div class="alert alert-danger" role="alert">
		<h4 class="alert-heading">{{ appData().jobsites.errorMoveOrderLineHeader }}</h4>
		<p class="mb-0">{{ appData().jobsites.errorMoveOrderLineBody }}</p>
	</div>
}

@if (IsSuccess !== true && !sendingRequest) {
	<div>
		<div class="calendars-container">
			<div>
				<label>{{ appData().jobsites.moveFromJs }}</label>
				<div class="move-from">{{ jobsite.Name }}</div>
			</div>
			<cramo-icon iconName="arrow_forward" />
			<div>
				<label>{{ appData().jobsites.moveToJs }}</label>
				@if (IsSuccess === undefined) {
					<cramo-search-select [largeSize]="true" (bySelect)="onJobsiteSelect($event)" [filterJobsiteId]="jobsite.Id" />
				} @else {
					<div class="move-from">{{ jobsiteTo?.Name }}</div>
				}
			</div>
		</div>

		<div class="move-orderline-row-container">
			<div
				class="move-orderline-row"
				*ngFor="let selectedOrder of ordersToMove"
				[ngClass]="{ failed: selectedOrder.OrderRow.IsSuccess === false }"
			>
				<div class="row">
					<div class="col-3 text-center">
						<img
							[ngClass]="{ 'no-image': !orderImages[selectedOrder.OrderId].hasImage }"
							[src]="orderImages[selectedOrder.OrderId].imageUrl"
							alt=""
						/>
						<div class="product-number">{{ selectedOrder.OrderRow.ItemId }}</div>
					</div>
					<div class="col-9 d-flex justify-content-center flex-column">
						<div class="row">
							<div class="col-md-10">
								<div class="product-name">{{ selectedOrder.OrderRow.ProductName }}</div>
								<div class="order-number">
									{{ appData().jobsites.orderItemOrderNumberHeadlineText }}: {{ selectedOrder.OrderRow.OrderId }}
								</div>
								<div>{{ appData().global.quantity }}: {{ selectedOrder.OrderRow.Quantity }}</div>
							</div>
							<div class="d-none d-md-block col-md-2 align-self-center">
								@if (selectedOrder.OrderRow.IsSuccess === true) {
									<cramo-icon iconName="done" />
								}
								@if (selectedOrder.OrderRow.IsSuccess === false) {
									<cramo-icon iconName="close" />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-12 col-md-6">
				<label>{{ appData().jobsites.plannedReturnDate }}</label>
				<cramo-calendar
					[calendarOptions]="plannedReturnDateCalendarOptions"
					[disabled]="IsSuccess !== undefined"
					(byDateChange)="onReturnDateChange($event)"
				/>
			</div>
			<div class="col-12 col-md-6 consent">
				<h5>{{ appData().jobsites.moveOrderLinesConsentHeader }}</h5>
				<article>
					<cramo-checkbox-old class="checkbox" (byCheckChanged)="toggleCheckbox()" [borderColor]="'#6C757D'" />
					<p>{{ appData().jobsites.moveOrderLinesConsentText }}</p>
				</article>
			</div>
		</div>
	</div>
}

@if (sendingRequest) {
	<cramo-loading-area [title]="appData().jobsites.sendingMoveOrderLineRequestMessage" [showTransparent]="true" />
}

@if (IsSuccess === true) {
	<div class="align-items-center success-container">
		<div>
			{{ appData().jobsites.orderRowsSuccessfullyMoved }}
		</div>
	</div>
}
