import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment, { Moment } from 'moment-mini';
import { EpiService } from '../../services/epi/epi.service';

@Component({
	selector: 'cramo-calendar-month',
	templateUrl: './calendar-month.component.html',
	styleUrls: ['./calendar-month.component.scss'],
})
export class CalendarMonthComponent implements OnInit {
	constructor(private epiService: EpiService) {}

	@Input() public alignLeft = true;
	@Input() public error = false;
	@Input()
	public set disabled(value: boolean) {
		this._disabled = value;
	}
	@Input()
	public set calendarOptions(value: MonthCalendarOptions) {
		if (value) {
			this._calendarOptions = value;
			this.setCurrentMonth();
			this.generateTiles();
		}
	}

	@Output() public byDateChange: EventEmitter<Moment> = new EventEmitter<Moment>();

	private _calendarOptions: MonthCalendarOptions;
	public _disabled = false;

	public monthMap: Record<number, string> = {};
	public currentYear = moment().year();
	public currentMonth = moment().month();

	public calendarVisible = false;
	public selectedPeriodLabel: string;
	public tiles: Tile[] = [];

	ngOnInit() {
		if (this.epiService.appData?.calendar) {
			const months = this.epiService.appData.calendar.months;
			Object.values(months).forEach((val: string, index: number) => (this.monthMap[index] = val));
		}

		this.selectedPeriodLabel =
			typeof this.epiService.appData?.statistics?.selectedPeriod !== 'undefined'
				? this.epiService.appData.statistics.selectedPeriod
				: 'Selected period';
	}

	public toggleCalendar() {
		if (this.calendarVisible) {
			this.closeCalendar();
		} else {
			this.generateTiles();
			this.calendarVisible = true;
		}
	}

	public closeCalendar() {
		this.calendarVisible = false;
	}

	private generateTiles() {
		const numberOfTiles = 12;

		let monthMoment = moment().date(1).month('January').year(this.currentYear);

		this.tiles = [];
		for (let i = 0; i < numberOfTiles; i++) {
			const clonedMonth = monthMoment.clone();
			this.tiles.push(new Tile(i, clonedMonth, this._calendarOptions));
			monthMoment = monthMoment.add(1, 'month');
		}
	}

	private getFirstDayOfMonth(dateSelected: Moment): Moment {
		const date = dateSelected.startOf('month').hour(12);
		return date;
	}

	private getLastDayOfMonth(dateSelected: Moment): Moment {
		const date = dateSelected.endOf('month').hour(12);
		return date;
	}

	public changeYear(amount: 1 | -1) {
		const year = moment().month(this.currentMonth).year(this.currentYear);
		year.add(amount, 'year');
		this.currentMonth = year.month();
		this.currentYear = year.year();
		this.generateTiles();
	}

	public setDate(tile: Tile) {
		if (tile.isClickable) {
			let selectedDate: Moment;
			if (this._calendarOptions.isStartCalendar) {
				this._calendarOptions.startDate = this.getFirstDayOfMonth(tile.date);
				selectedDate = this.getFirstDayOfMonth(tile.date);
			} else {
				this._calendarOptions.endDate = this.getLastDayOfMonth(tile.date);
				selectedDate = this.getLastDayOfMonth(tile.date);
			}
			this.currentMonth = tile.date.month();

			setTimeout(() => {
				this.generateTiles();
				this.byDateChange.emit(selectedDate);
				this.closeCalendar();
			}, 0);
		}
	}

	private setCurrentMonth() {
		if (this._calendarOptions.isStartCalendar) {
			this.currentMonth = this._calendarOptions.startDate.month();
			this.currentYear = this._calendarOptions.startDate.year();
		} else if (!this._calendarOptions.isStartCalendar) {
			this.currentMonth = this._calendarOptions.endDate.month();
			this.currentYear = this._calendarOptions.endDate.year();
		}
	}
}

export class Tile {
	constructor(month: number, date: Moment, cal: MonthCalendarOptions) {
		this.month = month;
		this.date = date;

		if (date) {
			// Check if this is the selected date
			if (
				(cal.startDate && cal.startDate.isSame(date, 'month') && cal.isStartCalendar) ||
				(cal.endDate && cal.endDate.isSame(date, 'month') && !cal.isStartCalendar)
			) {
				this.isSelected = true;
			}

			// Check if this is selected date for other calendar (end/start)
			if (
				(cal.startDate && cal.startDate.isSame(date, 'month') && !cal.isStartCalendar) ||
				(cal.endDate && cal.endDate.isSame(date, 'month') && cal.isStartCalendar)
			) {
				this.isOtherSelected = true;
			}

			// Check if date is clickable
			if (cal.isStartCalendar) {
				if (date.isBefore(cal.firstAvailableDate, 'month') || date.isAfter(cal.endDate, 'month')) {
					this.isClickable = false;
				} else {
					this.isClickable = true;
				}
			} else {
				if ((cal.startDate && date.isBefore(cal.startDate, 'month')) || date.isAfter(cal.lastAvailableDate, 'month')) {
					this.isClickable = false;
				} else {
					this.isClickable = true;
				}
			}

			// Check if date is in between start and end date
			if (date.isAfter(cal.startDate) && date.isBefore(cal.endDate)) {
				this.isBetweenStartAndEnd = true;
			} else {
				this.isBetweenStartAndEnd = false;
			}
		} else {
			this.isClickable = false;
		}
	}
	public isClickable = false;
	public date: Moment;
	public month: number;
	public isSelected = false;
	public isOtherSelected = false;
	public isBetweenStartAndEnd = false;
	public isToday = false;
}

export class MonthCalendarOptions {
	constructor(
		startDate: Moment,
		endDate: Moment,
		isStartCalendar: boolean,
		firstAvailableDate: Moment,
		lastAvailableDate: Moment,
	) {
		this.startDate = startDate;
		this.endDate = endDate;
		this.isStartCalendar = isStartCalendar;
		this.firstAvailableDate = firstAvailableDate;
		this.lastAvailableDate = lastAvailableDate;
	}

	public startDate: Moment;
	public endDate: Moment;
	public isStartCalendar: boolean;
	public firstAvailableDate: Moment;
	public lastAvailableDate: Moment;
}
