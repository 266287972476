import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarketService } from './market.service';

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	constructor(
		private httpClient: HttpClient,
		private marketService: MarketService,
	) {}

	public moveOrderLinesBetweenJobsites(request: MoveOrderLinesRequestModel) {
		return this.httpClient.post<MovedOrderLine[]>(
			`/webapi/auth/${this.marketService.currentMarket}/moveorderlines?language=${this.marketService.currentLanguage}`,
			request,
		);
	}
}
export class MovedOrderLine {
	public OrderId: number;
	public OrderRowId: number;
	public IsSuccess: string;
}

export class MoveOrderLinesRequestModel {
	constructor(
		fromJobsiteId: string,
		toJobsiteId: string,
		plannedReturnDate: Date,
		orderLines: MoveOrderLineRequestModel[],
	) {
		this.FromJobsiteId = fromJobsiteId;
		this.ToJobsiteId = toJobsiteId;
		this.PlannedReturnDate = plannedReturnDate;
		this.OrderLines = orderLines;
	}
	public FromJobsiteId: string;
	public ToJobsiteId: string;
	public PlannedReturnDate: Date;
	public OrderLines: MoveOrderLineRequestModel[];
}

export class MoveOrderLineRequestModel {
	constructor(orderId: number, orderRowId: number) {
		this.OrderId = orderId;
		this.OrderRowId = orderRowId;
	}
	public OrderId: number;
	public OrderRowId: number;
}
