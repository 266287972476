import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CartOrderRow } from '../types/add-to-cart-success-dialog.types';

@Injectable()
export class AddToCartSuccessDialogService {
	private httpClient = inject(HttpClient);

	public getCartOrderRowById(cartOrderRowId: string): Observable<CartOrderRow> {
		const url = `/webapi/add-to-cart-success-dialog/${cartOrderRowId}/cart-order-row`;
		return this.httpClient.get<CartOrderRow>(url);
	}
}
