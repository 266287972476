<div class="fcolumn-start-stretch">
	<div class="frow-center-center position-relative">
		@if (currentField === 'image') {
			<img [src]="currentImageSrc + '?width=300'" (click)="showPreview()" class="image" [alt]="imageName" />
			@if (showEcoBadge) {
				<img class="eco-badge" src="/assets/images/cramo_eco_choice.svg" width="80px" alt="Cramo eco badge" />
			}
		}

		<div class="badge-container">
			@if (show247Badge) {
				<div class="twentyfourseven-badge">24/7</div>
			}
		</div>
		@if (currentField === 'video') {
			<iframe
				width="300"
				height="300"
				[attr.src]="currentImageSrc"
				[title]="imageName"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		}
	</div>
	@if (showThumbs) {
		<div class="frow-between-center">
			<span (click)="scrollLeft()" [ngClass]="{ 'disable-arrow': !leftThumbEnabled }" class="arrow">
				<cramo-icon iconName="chevron_left" iconDisplay="inline" />
			</span>
			<div
				*ngFor="let thumb of thumbArray; let i = index"
				class="image-container"
				[ngClass]="{ 'selected-thumb': currentImageSrc === thumb.url }"
			>
				@if (thumb.field === 'image') {
					<img [src]="thumb.url + '?width=50'" (click)="selectImage(thumb, i)" [alt]="imageName" />
				}

				@if (thumb.field === 'video') {
					<img src="/assets/icons/icon_play.svg" width="50px" (click)="selectImage(thumb, i)" [alt]="imageName" />
				}
			</div>
			<span (click)="scrollRight()" [ngClass]="{ 'disable-arrow': !rightThumbEnabled }" class="arrow">
				<cramo-icon iconName="chevron_right" iconDisplay="inline" />
			</span>
		</div>
	}

	<div class="text frow-center-center">{{ text }}</div>
</div>

<ng-template #imageModal>
	<cramo-modal (byClose)="closePriceDetailsModal()">
		<div modal-body class="modal-body">
			<img [src]="currentImageSrc + '?width=600'" [alt]="imageName" />
		</div>
	</cramo-modal>
</ng-template>
