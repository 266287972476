<div class="product-list__container">
	<div class="product-list" data-test-id="product-card-list">
		<div class="product-list__item" *ngFor="let product of localProducts | slice: 0 : showMoreLimit; let i = index">
			<cramo-product-card [product]="product" [index]="i" />
		</div>
	</div>
	<button *ngIf="showMoreLimit < localProducts.length" class="w-100 text-center p-4 show-more" (click)="showMore()">
		<span *ngIf="appData$ | async as appData"> {{ appData?.productCategory?.showMore }}</span>
	</button>
</div>
