import { Component, DestroyRef, Input, OnDestroy, OnInit, Signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import moment, { Moment } from 'moment-mini';
import { combineLatest } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AvailabilityUtilsService } from '../../services/availability-utils.service';
import { CartOldService } from '../../services/cart/cart-old.service';
import { ConfigurationService } from '../../services/configuration.service';
import { CreditCheckService } from '../../services/credit-check.service';
import { DepotService } from '../../services/depot/depot.service';
import { EpiService } from '../../services/epi/epi.service';
import { EventBusService } from '../../services/event-bus.service';
import { GtmService } from '../../services/gtm.service';
import { JobsiteService } from '../../services/jobsite/jobsite.service';
import { MarketService } from '../../services/market.service';
import { PriceUtilsService } from '../../services/price-utils.service';
import { ProductUtilsService } from '../../services/product-utils.service';
import { ProductService } from '../../services/product.service';
import { PunchoutService } from '../../services/punchout.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user/user.service';
import { AddEditCartItemOptions, ItemsInPackageMap } from '../../types/cart-old.types';
import { Depot } from '../../types/depot.types';
import { Jobsite } from '../../types/jobsite.types';
import { CartStore } from '../../types/order.types';
import { AvailabilityResponseDto, ProductPriceInfo, RegionAvailabilityRequest } from '../../types/product.types';
import { User } from '../../types/user.types';
import { DeliveryType, PickupType } from '../../utils/constants';
import { CreditControlInformationComponent } from '../credit-control-information/credit-control-information-component';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { AddEditCartBase, CART_STORAGE } from './add-edit-cart-base-old';

interface DepotInfo {
	depot: Depot;
	availability: number;
}

@Component({
	selector: 'cramo-add-to-cart',
	templateUrl: './add-edit-cart-item-old.component.html',
	styleUrls: ['./add-edit-cart-item-old.component.scss'],
})
export class AddToCartComponent extends AddEditCartBase implements OnInit, OnDestroy {
	@ViewChild(SearchSelectComponent) public searchSelect: SearchSelectComponent;
	private user: Signal<User>;
	private sessionCart: CartStore;
	public addingToCart = false;
	public isCreditApproved = toSignal(this.creditCheckService.isCreditApproved$);

	@Input()
	public set options(value: AddEditCartItemOptions) {
		if (value && !this.product) {
			this.product = value.product;
			this.updateComponentState();
			this.initComponent();
		}
	}

	@Input()
	public set prices(priceInfoList: ProductPriceInfo[]) {
		if (priceInfoList) {
			this.pricesArray = priceInfoList;
			this.cartService.productPackaging.set({
				...this.cartService.productPackaging(),
				...priceInfoList
					.filter((price) => price.Packaging)
					.reduce((acc: ItemsInPackageMap, priceInfo) => {
						return {
							...acc,
							[priceInfo.Packaging.PackagingName]: priceInfo.Packaging.ItemsInPackage,
						};
					}, {}),
			});
			this.cartService.updateProductQuantities();
		}
	}

	constructor(
		epiService: EpiService,
		configurationService: ConfigurationService,
		productUtil: ProductUtilsService,
		productService: ProductService,
		priceUtils: PriceUtilsService,
		marketService: MarketService,
		depotService: DepotService,
		userService: UserService,
		cartService: CartOldService,
		punchoutService: PunchoutService,
		protected availabilityUtils: AvailabilityUtilsService,
		private gtmService: GtmService,
		private storageService: StorageService,
		private eventBusService: EventBusService,
		private jobsiteService: JobsiteService,
		private authService: AuthService,
		private destroyRef: DestroyRef,
		private dialog: MatDialog,
		private creditCheckService: CreditCheckService,
	) {
		super(
			epiService,
			configurationService,
			productUtil,
			productService,
			priceUtils,
			marketService,
			depotService,
			userService,
			punchoutService,
			cartService,
		);

		this.isEditCartComponent = false;
		this.user = toSignal(this.userService.user$);
	}

	ngOnInit(): void {
		this.depotService.currentDepot$.pipe(takeUntil(this.onDestroy$)).subscribe((depot) => (this.currentDepot = depot));

		this.appData$ = this.epiService.appData$;
		this.logisticsRules = this.epiService.logisticsRules;
		this.configurations = this.configurationService.configurations;
		this.holidays = this.configurations.calendarHolidays;

		this.userService.user$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
			this.onUserChange();
		});

		this.updateComponentState();

		this.eventBusService.resetCartSubject.subscribe((resetCart: boolean) => {
			if (resetCart) {
				this.storageService.removeItem(this.getStorageKey());
				this.selectedJobsite = null;
				this.jobsiteSelectComponent.clearSelectedJobsite();
			}
		});
		this.depotFilter = this.selectedPickupType === PickupType.BOX ? 'box' : 'none';

		if (
			this.userService.hasPermissionSync('RENT_PICKUP') &&
			!this.userService.hasPermissionSync('RENT_TRANSPORT') &&
			!this.userService.hasPermissionSync('RENT_BOX_PICKUP')
		) {
			this.canOnlyRentPickup = true;
			this.selectedDeliveryType.set(DeliveryType.PICKUP);
			this.jobsiteService.jobsites$.pipe(takeUntil(this.onDestroy$)).subscribe((jobsites: Jobsite[]) => {
				if (jobsites) {
					this.selectedJobsite = jobsites[0];
					this.parseAvailability();
				}
			});
		} else {
			this.canOnlyRentPickup = false;
		}

		this.getInfoFromSession();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	private getStorageKey() {
		return `${CART_STORAGE}_${this.user().IdpUserId}_${this.user().CustomerInfo.CustomerId}`;
	}

	private initComponent() {
		this.depotService.currentDepot$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((depot) => {
			this.currentDepot = depot;
		});
	}

	private getInfoFromSession() {
		if (this.isLoggedIn()) {
			this.sessionCart = this.storageService.getItem(this.getStorageKey());
			if (this.product) {
				setTimeout(() => {
					if (this.sessionCart) {
						this.selectedJobsite = this.sessionCart.Jobsite;
						this.jobsiteSelectComponent.setJobsite(this.selectedJobsite);
						this.runAvailability();
					} else {
						combineLatest([this.userService.user$, this.jobsiteService.jobsites$]).subscribe(([user, jobsites]) => {
							if (!user.DefaultJobSiteId) {
								return;
							}

							this.selectedJobsite = jobsites.find((js) => js.Id === user.DefaultJobSiteId);
							this.jobsiteSelectComponent.setJobsite(this.selectedJobsite);
							this.runAvailability();
						});
					}
				}, 0);
			}
		}
	}

	private onUserChange() {
		this.getInfoFromSession();
		this.logisticsRules = this.epiService.logisticsRules;
		this.canRent =
			this.userService.hasPermissionSync('RENT_TRANSPORT') ||
			this.userService.hasPermissionSync('RENT_PICKUP') ||
			this.userService.hasPermissionSync('RENT_BOX_PICKUP');
		this.canOnlyRentPickup =
			this.userService.hasPermissionSync('RENT_PICKUP') &&
			!this.userService.hasPermissionSync('RENT_TRANSPORT') &&
			!this.userService.hasPermissionSync('RENT_BOX_PICKUP');

		if (this.product) {
			this.initComponent();
		}
	}

	public onDepotSelected(depotInfo: DepotInfo) {
		if (depotInfo.depot.Id === this.currentDepot?.Id) return;

		this.currentDepot = depotInfo.depot;
		this.currentDepotAvailability = depotInfo.availability;
		this.productAvailability = depotInfo.availability;
		this.parseAvailability();
		this.updateComponentState();
	}

	public onStartDateChange(date: Moment) {
		this.startCalendar.startDate = date;
		this.parseAvailability();
		this.selectedTime = '';
		this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
		this.setStepperOptions();
		this.updateComponentState();
	}

	public onFlipPanelOpen() {
		this.setSelectedDeliveryType();
		this.isFirstTime = true;
		this.userService.user$.pipe(take(1), takeUntil(this.onDestroy$)).subscribe(() => {
			this.onUserChange();
		});
	}

	public override getProductAvailability(): void {
		this.runAvailability();
	}

	private runAvailability() {
		if (this.selectedJobsite?.Id && this.currentDepot?.Id) {
			this.checkingProductAvailability = true;

			if (this.selectedDeliveryType() === DeliveryType.PICKUP) {
				this.isLoading = true;
			}
			this.updatingOrder = true;

			const date = this.availabilityUtils.addWeekDays(moment(), 2, this.holidays);
			const item = this.availabilityUtils.createAvailabilityCall(this.product, date, this.currentDepot);

			const { UseEarliestPossibleDeliveryDay } = this.epiService.logisticsRules.LogisticRules;
			const isTransportDelivery = this.selectedDeliveryType() === DeliveryType.TRANSPORT;

			const availabilityRequest: RegionAvailabilityRequest = {
				IsTransportDelivery: isTransportDelivery && UseEarliestPossibleDeliveryDay,
				AvailabilityRequestModels: [item],
				JobsiteId: this.selectedJobsite.Id,
				CategoryId: this.product?.CategoryId,
				Language: this.marketService.currentLanguage,
			};

			this.productService
				.getRegionAvailability(availabilityRequest)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((availability: AvailabilityResponseDto[]) => {
					this.productAvailability = availability[0].TotalQuantityPossibleAvailableNoReturn;
					this.currentDepotAvailability = availability[0].TotalQuantityPossibleAvailableNoReturn;
					this.quantityAvailableAtDeliveryUnit = availability[0].QuantityAvailableAtDeliveryUnit;
					this.quantityAvailableInDeliveryArea = availability[0].QuantityAvailableInDeliveryArea;
					this.productAvailabilityResponse = availability[0];
					this.parseAvailability();
					this.checkingProductAvailability = false;
					this.updatingOrder = false;
					this.isLoading = false;
				});
		}
	}

	private parseAvailability() {
		if (!this.logisticsRules) {
			return;
		}
		const startDate = this.startCalendar?.startDate ?? moment();

		this.calculatedAvailability = this.availabilityUtils.calculateAvailability({
			deliveryType: this.selectedDeliveryType(),
			pickupType: this.selectedPickupType,
			logisticsRules: this.epiService.logisticsRules,
			configurations: this.configurationService.configurations,
			availability: this.currentDepotAvailability,
			quantityAvailableAtDeliveryUnit: this.quantityAvailableAtDeliveryUnit,
			quantityAvailableInDeliveryArea: this.quantityAvailableInDeliveryArea,
			date: startDate,
			market: this.marketService.currentMarket,
			pickupOnSaturdays: this.checkDepotRuleForSaturdays(),
		});

		if (this.currentDepot) {
			const brandTypeItemNumber = this.product?.Properties?.General?.BrandTypeItemNumber.Value;
			const currentDepotId = this.currentDepot.Id;
			// Subtracting the amount in cart
			this.productAvailability =
				this.calculatedAvailability.availability -
				(this.cartService.depotCartProductQuantities()[currentDepotId]?.[brandTypeItemNumber] ?? 0);
		}

		if (this.productAvailability <= 0) {
			this.productAvailability = 0;
			this.isOrderDateValid = false;
		} else {
			this.isOrderDateValid = true;
		}

		if (this.sessionCart && this.isFirstTime) {
			this.validateAndSetSavedDataFromSession();
			this.isFirstTime = false;
		} else {
			const endDate = this.endCalendar?.endDate?.isSameOrAfter(this.calculatedAvailability.selectedDate)
				? this.endCalendar.endDate
				: null;

			this.createCalendarOptions(
				this.calculatedAvailability.selectedDate,
				endDate,
				this.calculatedAvailability.firstAvailableDate,
				this.holidays,
			);
			this.setStepperOptions();
			this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
			this.updateComponentState();
		}
	}

	private validateAndSetSavedDataFromSession() {
		this.setDates();
		this.setQuantity();
		this.deliveryTimes = this.calculatedAvailability.transportDeliveryTimes;
	}

	private setDates() {
		const startDate = this.startCalendar?.startDate ?? this.calculatedAvailability.firstAvailableDate;
		const endDate = this.endCalendar?.endDate ?? null;
		this.createCalendarOptions(startDate, endDate, this.calculatedAvailability.firstAvailableDate, this.holidays);
		this.updateComponentState();
	}

	private setQuantity() {
		this.setStepperOptions();
		this.updateComponentState();
	}

	public override addToCart() {
		if (!this.isLoggedIn()) {
			return this.authService.login();
		}

		if (!this.isCreditApproved()) {
			return this.dialog.open(CreditControlInformationComponent);
		}

		this.addingToCart = true;
		const logisticHandlingCode = this.product.Properties.AverageDimension?.BrandTypeLogisticHandlingCode?.Value ?? null;

		const cartToStore: CartStore = {
			Jobsite: this.selectedJobsite,
			OrderRow: {
				DeliveryType: this.selectedDeliveryType(),
				PickupType: this.selectedPickupType,
				DeliveryDate: this.startCalendar.startDate?.format('YYYY-MM-DDTHH:mm:ssZ'),
				DeliveryTime: this.selectedDeliveryType() === DeliveryType.TRANSPORT ? this.selectedTime : '',
				ReturnDate: this.endCalendar.endDate?.format('YYYY-MM-DD'),
				Quantity: this.selectedQuantity,
				Category: this.product.Category,
				LogisticHandlingCode: logisticHandlingCode,
				WeightKG: this.product.WeightKG,
				Unit: this.selectedUnit,
			},
		};

		this.storageService.setItem(this.getStorageKey(), cartToStore);

		this.cartService
			.addToCart(
				this.selectedJobsite.Id,
				this.selectedDeliveryType(),
				this.selectedPickupType,
				this.startCalendar.startDate,
				this.product.Id,
				this.selectedQuantity,
				this.endCalendar.endDate,
				this.currentDepot.Id,
				this.selectedDeliveryType() === DeliveryType.TRANSPORT ? this.selectedTime : '',
				this.product.CategoryId,
				logisticHandlingCode,
				this.marketService.currentMarket,
				this.product.WeightKG,
				this.selectedUnit,
			)
			.pipe(
				tap({
					error: () => {
						this.addingToCart = false;
					},
				}),
			)
			.subscribe(() => {
				this.isFirstTime = true;
				this.closePanel.emit(true);
				this.runAvailability();
				this.gtmService.addToCart(
					this.product,
					this.selectedQuantity,
					this.calculatedAvailability.availability,
					this.marketService.currentCurrency,
				);
				this.selectedQuantity = 0;
				this.addingToCart = false;
			});
	}
}

export type PrevSelectedDeliveryAndPickup = {
	deliveryType: DeliveryType;
	pickupType: PickupType;
};
