import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EpiService } from '../../../services/epi/epi.service';
import { Product } from '../../../types/product.types';
import { AppData } from '../../../types/translations.types';

@Component({
	selector: 'cramo-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
	constructor(private epiService: EpiService) {}

	@Input()
	public set products(value: Product[]) {
		this.showMoreLimit = this.showMoreLimitStart;
		this.localProducts = value;
	}
	@Input() public wide: boolean;

	public appData$: Observable<AppData>;
	public localProducts: Product[] = [];
	public showMoreLimit: number;

	private showMoreStep: number;
	private showMoreLimitStart: number;

	ngOnInit() {
		this.showMoreLimitStart = 36;
		this.showMoreStep = 24;
		this.appData$ = this.epiService.appData$;
		this.showMoreLimit = this.showMoreLimitStart;
	}

	public showMore() {
		this.showMoreLimit += this.showMoreStep;
	}
}
