import { Component, computed, input, Input, Signal, TemplateRef, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from '../../services/configuration.service';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { SupportedLanguage } from '../../types/market.types';
import { Product, ProductPriceInfo } from '../../types/product.types';

@Component({
	selector: 'cramo-product-price',
	templateUrl: './product-price.component.html',
	styleUrls: ['./product-price.component.scss'],
})
export class ProductPriceComponent {
	protected readonly featureToggles = toSignal(this.configurationService.featureToggles$);
	protected appData = toSignal(this.epiService.appData$);
	protected defaultPriceTranslation: Signal<string>;
	protected currency = this.configurationService.configurations.currency;

	public market: string;
	public dialogModalRef: MatDialogRef<TemplateRef<never>>;
	public currentLanguage: SupportedLanguage;
	public readonly prices = input.required<ProductPriceInfo[]>();

	@Input() public product: Product;

	@ViewChild('priceDetailslModal', { static: true }) public priceDetailslModaltemplate: TemplateRef<never>;

	constructor(
		private epiService: EpiService,
		private dialog: MatDialog,
		private marketService: MarketService,
		private configurationService: ConfigurationService,
	) {
		this.market = this.marketService.currentMarket;
		this.featureToggles = toSignal(this.configurationService.featureToggles$);
		this.currentLanguage = this.marketService.currentLanguage;
	}

	public defaultPrice = computed(() => {
		if (!this.prices() || this.prices().length === 0) {
			return null;
		}
		return this.getDefaultPrice(this.prices());
	});

	public getDefaultPrice(prices: ProductPriceInfo[]): ProductPriceInfo {
		return prices.find((priceInfo) => priceInfo.IsDefaultTimeUnit);
	}

	public openPriceDetailsModal(): void {
		this.dialogModalRef = this.dialog.open(this.priceDetailslModaltemplate, {
			maxWidth: '600px',
			minWidth: '320px',
			width: '100%',
		});
	}

	public closePriceDetailsModal(): void {
		if (this.dialogModalRef) {
			this.dialogModalRef.close();
		}
	}
}
