<h5 mat-dialog-title [textContent]="appData().cart.successModalTitle"></h5>

<mat-dialog-content class="content">
	<p>{{ appData().cart.successModalPreamble }}</p>

	<cramo-card [hideShadow]="true">
		<div class="cart-product-info-container" [ngClass]="{ loading: !orderRow() }">
			@if (orderRow()) {
				<img cramoFadeInImageOnLoad [src]="orderRow().imageUrl + '?width=160'" alt="" class="product-image" />
			} @else {
				<div class="product-image"></div>
			}
			<div class="info">
				<p class="name">{{ orderRow()?.name }}</p>
				<p class="product-name">{{ orderRow()?.brandAndType }}</p>
				<p class="quantity">{{ appData().cart.successModalQuantityPrefix }}: {{ orderRow()?.quantity }}</p>
			</div>
		</div>
	</cramo-card>

	<div class="buttons">
		<cramo-button [text]="appData().cart.successModalShopMoreButtonText" buttonStyle="stroked" (onclick)="close()" />
		<cramo-button
			[text]="appData().cart.successModalCartButtonText"
			buttonColor="primary"
			prefixIcon="shopping_cart"
			routerLink="/cart"
			(onclick)="close()"
		/>
	</div>
</mat-dialog-content>

<cramo-button mat-dialog-close prefixIcon="close" class="close-dialog" />
