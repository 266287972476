import { Directive, EventEmitter, Output, Signal, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Moment } from 'moment-mini';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AvailabilityResult } from '../../services/availability-utils.service';
import { CartOldService } from '../../services/cart/cart-old.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DepotService } from '../../services/depot/depot.service';
import { LogisticsRulesDto } from '../../services/epi/epi.model';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { PriceUtilsService } from '../../services/price-utils.service';
import { ProductUtilsService } from '../../services/product-utils.service';
import { ProductService } from '../../services/product.service';
import { PunchoutService } from '../../services/punchout.service';
import { UserService } from '../../services/user/user.service';
import { Configurations, FeatureToggles } from '../../types/configuration.types';
import { Depot } from '../../types/depot.types';
import { Jobsite } from '../../types/jobsite.types';
import { SupportedLanguage } from '../../types/market.types';
import { DeliveryTime } from '../../types/order.types';
import { AvailabilityResponseDto, Product, ProductPriceInfo, SalesUnit } from '../../types/product.types';
import { AppData } from '../../types/translations.types';
import { DeliveryType, PickupType } from '../../utils/constants';
import { CalendarOptions } from '../calendar/calendar-options';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { StepperOldComponent } from '../stepper-old/stepper-old.component';
import { StepperOptions } from '../stepper-old/types/stepper-old.types';

export const CART_STORAGE: string = 'CART_STORAGE';

@Directive()
export class AddEditCartBase {
	private isPunchout: Signal<boolean>;
	private cartCount: Signal<number>;
	public isPunchoutAndHasItemsInCart: Signal<boolean>;

	public currentDepot: Depot;
	public currentDepotAvailability = 0;

	public appData$: Observable<AppData>;
	public canOnlyRentPickup: boolean;

	protected logisticsRules: LogisticsRulesDto;
	protected configurations: Configurations;
	protected isLoggedIn: Signal<boolean>;
	protected canRent: boolean;
	public featureToggles: Signal<FeatureToggles>;

	// Delivery
	public selectedDeliveryType: WritableSignal<DeliveryType | null> = signal(null);
	public deliveryType = DeliveryType;
	public selectedPickupType: PickupType = PickupType.STORE;
	public pickupType = PickupType;

	// Calendar
	public startCalendar: CalendarOptions;
	public endCalendar: CalendarOptions;
	protected holidays: string[];

	// Depot
	public selectedDepotHasBox = true;
	public depotFilter: 'none' | 'box';

	// Quantity
	public stepperOptions: StepperOptions;
	public selectedQuantity = 1;
	public currentLanguage: SupportedLanguage;
	public selectedUnit: SalesUnit = null;

	// Jobsite
	protected selectedJobsite: Jobsite;

	// Time
	public deliveryTimes: DeliveryTime[];
	public selectedTime: string = null;

	// Price
	public pricesArray: ProductPriceInfo[] = [];

	// Component Enabled Flags
	public startCalendarEnabled = false;
	public endCalendarEnabled = false;
	public deliveryMethodEnabled = false;
	public jobsiteEnabled = false;
	public timeEnabled = false;
	public quantityEnabled = false;
	public addToCartEnabled = false;

	public checkingProductAvailability = false;
	public updatingOrder = false;
	private isModalOpen = false;
	public isOrderDateValid = true;
	public isOrder = false;
	public isMerchandise = false;
	public is247Product = false;
	public isLoading = false;
	protected isEditCartComponent: boolean;

	// Availability
	protected calculatedAvailability: AvailabilityResult;
	protected productAvailability = 0;
	protected productAvailabilityResponse: AvailabilityResponseDto;
	protected quantityAvailableAtDeliveryUnit: number;
	protected quantityAvailableInDeliveryArea: number;

	protected isFirstTime = true;

	protected product: Product;

	public deliveryMethodRadioId1: string;
	public deliveryMethodRadioId2: string;
	public deliveryMethodRadioName: string;

	@Output() public closePanel: EventEmitter<boolean> = new EventEmitter();
	@Output() public modalStateChanged: EventEmitter<boolean> = new EventEmitter();

	// components
	@ViewChild(SearchSelectComponent) public jobsiteSelectComponent: SearchSelectComponent;
	@ViewChild(StepperOldComponent) public stepperComponent: StepperOldComponent;

	protected onDestroy$ = new ReplaySubject(1);

	constructor(
		protected epiService: EpiService,
		protected configurationService: ConfigurationService,
		protected productUtil: ProductUtilsService,
		protected productService: ProductService,
		protected priceUtils: PriceUtilsService,
		protected marketService: MarketService,
		protected depotService: DepotService,
		protected userService: UserService,
		protected punchoutService: PunchoutService,
		protected cartService: CartOldService,
	) {
		this.appData$ = this.epiService.appData$;
		this.featureToggles = toSignal(this.configurationService.featureToggles$);
		this.currentLanguage = this.marketService.currentLanguage;

		this.isLoggedIn = toSignal(this.userService.isLoggedIn$);
		this.isPunchout = toSignal(this.punchoutService.isPunchout$);
		this.cartCount = toSignal(this.cartService.cartCount$.pipe(map(({ cartCount }) => cartCount)));
		this.isPunchoutAndHasItemsInCart = computed(() => {
			const allowedItems = this.isEditCartComponent ? 1 : 0;
			return this.isPunchout() && this.cartCount() > allowedItems;
		});

		this.configurationService.featureToggles$.pipe(take(1)).subscribe((featureToggles) => {
			const selectedDeliveryType = featureToggles.order.isPickupDefaultDelivery
				? DeliveryType.PICKUP
				: DeliveryType.TRANSPORT;
			this.selectedDeliveryType.set(selectedDeliveryType);
		});
	}

	protected createCalendarOptions(startDate: Moment, endDate: Moment, firstAvailableDate: Moment, holidays: string[]) {
		const modifiedEndDate = startDate.isAfter(endDate) ? null : endDate;

		const currentDepotIsOpenOnSaturdays = this.checkDepotRuleForSaturdays();
		const areSaturdaysAllowed = currentDepotIsOpenOnSaturdays;
		const areSundaysAllowed = this.selectedPickupType === PickupType.BOX;

		const startCalendar = new CalendarOptions(
			startDate,
			modifiedEndDate,
			true,
			firstAvailableDate,
			null,
			holidays,
			true,
			true,
			true,
			areSaturdaysAllowed,
			areSundaysAllowed,
		);

		const endCalendar = new CalendarOptions(
			startDate,
			modifiedEndDate,
			false,
			firstAvailableDate,
			null,
			holidays,
			true,
			true,
			true,
			currentDepotIsOpenOnSaturdays,
			false,
		);

		this.startCalendar = startCalendar;
		this.endCalendar = endCalendar;
	}

	public checkDepotRuleForSaturdays() {
		if (this.selectedDeliveryType() === DeliveryType.TRANSPORT) {
			return false;
		}
		const depotsOpenOnSaturdays = this.logisticsRules.LogisticRules.PickupRules.DepotsOpenOnSaturdays;

		return depotsOpenOnSaturdays?.split(',').includes(this.currentDepot?.IDSet.ID);
	}

	protected setStepperOptions(override?: number) {
		let currentVal: number;

		const calculatedProductAvailability = this.calculateProductAvailability();

		if (!override) {
			if (this.stepperOptions) {
				if (calculatedProductAvailability !== null && this.selectedQuantity > calculatedProductAvailability) {
					currentVal = calculatedProductAvailability;
					this.selectedQuantity = calculatedProductAvailability;
				} else {
					currentVal = this.selectedQuantity;
				}
			} else {
				currentVal = 1;
			}
		} else {
			currentVal = override;
		}

		this.stepperOptions = { max: calculatedProductAvailability, min: 1, value: currentVal };
	}

	protected calculateProductAvailability() {
		if (!this.isMerchandise) {
			return this.productAvailability;
		}

		let calculatedProductAvailability = this.productAvailability;

		const itemsInPackage = this.pricesArray.find(
			(priceObject) => priceObject.Packaging.PackagingName === this.selectedUnit,
		)?.Packaging?.ItemsInPackage;
		if (!itemsInPackage) {
			return this.productAvailability;
		}
		calculatedProductAvailability = this.calculateAvailablePackages(itemsInPackage);

		return calculatedProductAvailability;
	}

	private calculateAvailablePackages = (itemsInPackage: number): number =>
		Math.floor(this.productAvailability / itemsInPackage);

	public disablePriceUnitOption = (price: ProductPriceInfo): boolean =>
		this.calculateAvailablePackages(price.Packaging.ItemsInPackage) < 1;

	public setModalState(isOpen: boolean) {
		this.isModalOpen = isOpen;
		this.modalStateChanged.emit(this.isModalOpen);
	}

	protected updateComponentState() {
		// TODO: replace all this with reactive values tied to the template form
		this.jobsiteEnabled = false;
		this.deliveryMethodEnabled = false;
		this.startCalendarEnabled = false;
		this.endCalendarEnabled = false;
		this.quantityEnabled = false;
		this.timeEnabled = false;
		this.addToCartEnabled = false;
		if (this.product) {
			this.isMerchandise = this.productUtil.isMerchandise(this.product);
			this.is247Product = this.productUtil.is247Product(this.product);
		}

		this.deliveryMethodRadioId1 = `${this.product?.BrandTypeItemNumber}1`;
		this.deliveryMethodRadioId2 = `${this.product?.BrandTypeItemNumber}2`;
		this.deliveryMethodRadioName = `${this.product?.BrandTypeItemNumber}Name`;

		// If we arent logged no further changes are needed.
		if (!this.isLoggedIn()) {
			return;
		}

		const rentAllowed = this.canRent;

		// If we aren't allowed to rent, just do an early exit.
		if (!rentAllowed) {
			return;
		}

		this.jobsiteEnabled = rentAllowed;

		if (!this.selectedJobsite) {
			return;
		}

		this.deliveryMethodEnabled = rentAllowed;

		if (!this.selectedDeliveryType) {
			return;
		}

		this.startCalendarEnabled = rentAllowed;

		if (!this.startCalendar?.startDate) {
			return;
		}

		this.endCalendarEnabled = rentAllowed;
		this.timeEnabled = rentAllowed;

		// if no unit is selected, select the first one
		if (!this.selectedUnit && this.pricesArray.length > 0) {
			this.selectedUnit = this.pricesArray[0].Packaging?.PackagingName;
		}

		if (this.isMerchandise && !this.selectedUnit) {
			return;
		}

		if (this.isMerchandise || (this.endCalendar?.endDate && this.calculateProductAvailability() > 0)) {
			this.quantityEnabled = rentAllowed;

			if (this.selectedQuantity === 0) {
				return;
			}

			if (this.selectedDeliveryType() !== DeliveryType.TRANSPORT && !this.currentDepot) {
				return;
			}

			const isTransportSelected = this.selectedDeliveryType() === DeliveryType.TRANSPORT;
			const isTimeSelected = Boolean(this.selectedTime);
			const isPickup = this.selectedDeliveryType() === DeliveryType.PICKUP;
			const isBox = this.selectedPickupType === PickupType.BOX;
			if ((isTransportSelected && isTimeSelected) || isPickup || isBox) {
				this.addToCartEnabled = rentAllowed;
			}
		}
	}

	public onJobsiteChange(jobsite: Jobsite | Jobsite[]) {
		this.selectedJobsite = jobsite as Jobsite;
		this.getProductAvailability();
		if (this.selectedDeliveryType() === DeliveryType.TRANSPORT) {
			return;
		}
		this.updatingOrder = false;
		this.isLoading = false;
		this.updateComponentState();
	}

	public onPickupTypeChange(type: PickupType) {
		// need to re-fetch Availability in order to re-render the start calendar
		// this will be solved with a reactive form
		this.getProductAvailability();
		this.selectedPickupType = type;

		if (type === PickupType.BOX) {
			this.selectedDeliveryType.set(DeliveryType.PICKUP);
			this.getProductAvailability();
			this.depotFilter = 'box';
			// reset currently selected depot if pickup method is box
			// and the depot does not support it.
			if (!this.currentDepot?.HasBoxDelivery) {
				this.currentDepot = null;
			}
		} else {
			this.depotFilter = 'none';
		}

		this.resetCalendar();
	}

	public setSelectedDeliveryType() {
		if (this.featureToggles().order.isPickupDefaultDelivery && this.userService.hasPermissionSync('RENT_PICKUP')) {
			this.selectedDeliveryType.set(DeliveryType.PICKUP);
		} else if (this.canOnlyRentPickup) {
			this.selectedDeliveryType.set(DeliveryType.PICKUP);
		} else {
			this.selectedDeliveryType.set(DeliveryType.TRANSPORT);
		}
	}

	private resetCalendar() {
		this.startCalendar = undefined;
		this.endCalendar = undefined;
	}

	public onDeliveryChange(type: DeliveryType) {
		this.depotFilter = 'none';
		this.selectedDeliveryType.set(type);
		this.resetCalendar();
		this.getProductAvailability();

		if (type === DeliveryType.TRANSPORT) {
			this.selectedPickupType = PickupType.STORE;
			// depot might have been set to null due to Box alternative
			if (!this.currentDepot) {
				this.depotService.currentDepot$.subscribe((depot) => {
					this.currentDepot = depot;
				});
			}
		}
	}

	public onEndDateChange(date: Moment): void {
		this.createCalendarOptions(
			this.endCalendar.startDate,
			date,
			this.calculatedAvailability.firstAvailableDate,
			this.holidays,
		);
		this.updateComponentState();
	}

	public onQuantityChanged(quantity: number): void {
		this.selectedQuantity = quantity;
		this.updateComponentState();
	}

	public onTimeChanged(): void {
		this.updateComponentState();
	}

	public onUnitChanged(event: Event): void {
		this.selectedUnit = (event.target as HTMLSelectElement).value as SalesUnit;
		this.setStepperOptions();
	}

	public getTotalPrice(): number {
		const pricePerUnit = this.pricesArray.find(
			(priceObject) => priceObject.Packaging.PackagingName === this.selectedUnit,
		).Price;
		return pricePerUnit * this.selectedQuantity;
	}

	public getProductAvailability(): void {
		return;
	}

	public addToCart(): void {
		return;
	}

	public cancel() {
		if (this.isModalOpen) {
			this.setModalState(false);
		}
		this.closePanel.emit(true);
	}

	public saveOrder(): void {
		return;
	}
}
