<div class="main-calendar-container" [ngClass]="{ 'disabled-calendar': _disabled }">
	<div (click)="toggleCalendar()" class="input-container cursor frow-between-center" [ngClass]="{ error: error }">
		<span>{{ monthMap[currentMonth] }} {{ currentYear }}</span>
		<img class="icon" src="/assets/icons/icon_calendar.svg" alt="" />
	</div>

	@if (calendarVisible) {
		<div
			class="calendar-container fcolumn-start-center"
			[ngClass]="{ 'left-align': alignLeft, 'right-align': !alignLeft }"
			[cramoClickOutside]="calendarVisible"
			(onOutsideClicked)="closeCalendar()"
		>
			<div class="year-switch-container frow-between-center">
				<div class="arrow-handle arrow-area frow-start-center" (click)="changeYear(-1)">
					<cramo-icon iconName="arrow_backward" [size]="16" />
				</div>
				<span class="month-label noselect">{{ currentYear }}</span>
				<div (click)="changeYear(1)" class="arrow-handle frow-end-center">
					<cramo-icon iconName="arrow_forward" [size]="16" />
				</div>
			</div>

			<div class="tile-container frow-start-start fwrap">
				<div
					class="tile fcolumn-start-center"
					*ngFor="let tile of tiles"
					(click)="setDate(tile)"
					[ngClass]="{
						selected: tile.isSelected,
						enabled: tile.isClickable,
						'other-selected': tile.isOtherSelected,
						'tile-between-start-end': tile.isBetweenStartAndEnd,
					}"
				>
					<span class="tile-label" [ngClass]="{ disabled: !tile.isClickable }">{{ monthMap[tile.month] }} </span>
				</div>
			</div>

			<div class="description-container frow-start-center">
				<div class="frow-center-center">
					<div class="description-period"></div>
					<div class="tile-description">{{ selectedPeriodLabel }}</div>
				</div>
			</div>
		</div>
	}

	<br />
</div>
