import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	combineLatest,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	switchMap,
} from 'rxjs';
import {
	AddToCartSuccessDialogComponent,
	AddToCartSuccessDialogData,
} from '../../components/add-to-cart-success-modal/add-to-cart-success-dialog.component';
import { ConfigurationService } from '../configuration.service';
import { CreditCheckService } from '../credit-check.service';
import { UserService } from '../user/user.service';

@Injectable({
	providedIn: 'root',
})
export class CartService {
	private readonly httpClient = inject(HttpClient);
	private readonly matDialog = inject(MatDialog);
	private readonly creditCheckService = inject(CreditCheckService);
	private readonly userService = inject(UserService);
	private readonly configurationService = inject(ConfigurationService);

	private readonly isAddingToCartSubject = new BehaviorSubject<boolean>(false);
	public readonly isAddingToCart$: Observable<boolean> = this.isAddingToCartSubject.asObservable();

	private readonly cartItemCountSubject = new ReplaySubject<number>(1);
	public readonly cartItemCount$: Observable<number> = this.cartItemCountSubject.asObservable();

	constructor() {
		const isLoggedIn$ = this.userService.isLoggedIn$;
		const featureToggles$ = this.configurationService.featureToggles$;
		const isNewCartEnabled$ = featureToggles$.pipe(
			map(({ other }) => other.isNewCartEnabled),
			distinctUntilChanged(),
		);

		combineLatest([isLoggedIn$, isNewCartEnabled$])
			.pipe(
				takeUntilDestroyed(),
				filter(([isLoggedIn, isNewCartEnabled]) => isLoggedIn && isNewCartEnabled),
				switchMap(() => this.syncCartItemCount()),
			)
			.subscribe();
	}

	public syncCartItemCount(): Observable<void> {
		const endpoint = `/webapi/cart-v2/cartitemcount`;
		return this.httpClient
			.get<number>(endpoint)
			.pipe(map((cartItemCount) => this.cartItemCountSubject.next(cartItemCount)));
	}

	public addToCart(productId: string): Observable<void> {
		const endpoint = `/webapi/cart-v2/${productId}/add-to-cart`;

		this.isAddingToCartSubject.next(true);
		return this.creditCheckService.runCreditCheck().pipe(
			switchMap(() => this.httpClient.post(endpoint, {})),
			switchMap((response: { cartOrderRowId: string }) => {
				const dialogData: AddToCartSuccessDialogData = {
					cartOrderRowId: response.cartOrderRowId,
				};

				this.matDialog.open(AddToCartSuccessDialogComponent, { data: dialogData });
				return this.syncCartItemCount();
			}),
			finalize(() => {
				this.isAddingToCartSubject.next(false);
			}),
		);
	}

	public updateCartOrderRowQuantity({ cartOrderRowId, quantity }: { cartOrderRowId: string; quantity: number }) {
		const endpoint = `/webapi/cart-v2/quantity`;
		return this.httpClient.post<void>(endpoint, { cartOrderRowId, quantity }).pipe();
	}

	// We might want to make a request with a list of orderRowIds that is managed in this service to prevent to many requests
	public getProductAvailability(cartOrderRowId: string): Observable<number> {
		const endpoint = `/webapi/cart-v2/${cartOrderRowId}/availability`;
		return this.httpClient.get<number>(endpoint);
	}
}
