import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'cramo-depot-availability-badge',
	templateUrl: './depot-availability-badge.component.html',
	styleUrls: ['./depot-availability-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepotAvailabilityBadgeComponent {
	private _value: number;

	@Input()
	public set value(value: number) {
		this._value = value > 0 ? value : 0;
	}

	public get value() {
		return this._value;
	}
}
