@if (currentDepot) {
	<div class="availability">
		<div class="availability__content">
			@if (currentDepotAvailability > 0) {
				<cramo-icon class="success" iconName="check_circle" />
			}
			@if (currentDepotAvailability === 0) {
				<cramo-icon class="warning" iconName="warning" />
			}
			<div class="availability__text" [ngClass]="{ 'availability__text--loading skeleton-loading': !availability }">
				@if (currentDepotAvailability > 0) {
					<span> {{ currentDepotAvailability }} {{ appData.product.available }} {{ currentDepot.Name }} </span>
				}

				@if (currentDepotAvailability <= 0) {
					<span>
						{{ appData.product.notAvailable }} {{ logisticsRules.LogisticRules.NumberOfDaysToCheckAvailability }}
						{{ appData.product.days }} {{ currentDepot.Name }}
					</span>
				}
			</div>
		</div>
	</div>
}
