type AllowedPatterns = 'YYYY-MM-DD';

type Year = `${number}${number}${number}${number}`;
type Month = `0${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}` | `1${0 | 1 | 2}`;
type Day = `0${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}` | `${1 | 2}${number}` | `30` | `31`;

/**
 * Represents a date in the format YYYY-MM-DD.
 * - YYYY: 4-digit year
 * - MM: 2-digit month (01-12)
 * - DD: 2-digit day (01-31)
 */
export type DateString = `${Year}-${Month}-${Day}`;

export function formatDate(date: Date, pattern: AllowedPatterns) {
	const formatTokens: Record<string, string> = {
		YYYY: date.getFullYear().toString(),
		MM: (date.getMonth() + 1).toString().padStart(2, '0'),
		DD: date.getDate().toString().padStart(2, '0'),
		HH: date.getHours().toString().padStart(2, '0'),
		mm: date.getMinutes().toString().padStart(2, '0'),
		ss: date.getSeconds().toString().padStart(2, '0'),
	};

	return Object.entries(formatTokens).reduce((formattedDate, [token, value]) => {
		return formattedDate.includes(token) ? formattedDate.replace(token, value) : formattedDate;
	}, pattern);
}

/**
 * @returns {DateString | null} The date formatted as YYYY-MM-DD or null if the input date is null.
 */
export function dateOnly(date: Date | null): DateString | null {
	if (!date) return null;

	return formatDate(date, 'YYYY-MM-DD') as DateString;
}

export function isSameDate(date1: Date, date2: Date): boolean {
	return dateOnly(date1) === dateOnly(date2);
}

export function daysBetweenDates(date1: Date, date2: Date) {
	const start = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const end = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

	const differenceInTime = end.getTime() - start.getTime();
	const differenceInDays = differenceInTime / (1000 * 3600 * 24);

	return differenceInDays + 1;
}

export function formatHour(value: string): string {
	if (!value) {
		return '';
	}

	const hour = parseInt(value, 10);

	if (isNaN(hour) || hour < 0 || hour > 23) {
		return value;
	}

	// Format the hour into a two-digit string and append ":00"
	return `${hour.toString().padStart(2, '0')}:00`;
}
