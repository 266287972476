<div class="search-select-container" [cramoClickOutside]="isListVisible" (byOutsideClicked)="showList(false)">
	<div class="jobsite-selector__container" [ngClass]="{ large: largeSize }">
		<img
			class="jobsite-selector__icon"
			[ngClass]="{ 'jobsite-selector__icon--lg': largeSize }"
			src="/assets/icons/icon_jobsites_sm_grey.svg"
			alt=""
		/>
		<input
			#jobsiteSelector
			type="text"
			[id]="elementId"
			readonly
			[placeholder]="localizedLabels.chooseJobsite"
			(focus)="showList(true)"
			[disabled]="disabled || isLoadingJobsites"
			class="form-control jobsite-selector"
			[ngClass]="{
				'jobsite-selector--lg': largeSize,
				'jobsite-selector--selected': showSelectedState && (selectedJobsites.length > 0 || selectedOption),
			}"
			data-test-id="jobsite-selector"
		/>
		<cramo-icon iconName="spinner" *ngIf="isLoadingJobsites" />

		<ng-container *ngIf="!multiSelectMode">
			<div class="selected-jobsites-container" *ngIf="selectedOption" (click)="showList(true)">
				<div
					class="single-selected-item"
					[ngClass]="{ 'single-selected-item--disabled': disabled || isLoadingJobsites }"
				>
					<span class="single-selected-item__name">{{ selectedOption.Name }}</span>
					<span class="single-selected-item__address"
						>{{ selectedOption.Address!.StreetAddress }} {{ selectedOption.Address!.PostalCode }}
						{{ selectedOption.Address!.City }}</span
					>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="multiSelectMode && selectedJobsites.length > 0">
			<div class="selected-jobsites-container" (click)="showList(true)">
				<div class="multi-selected-item" *ngFor="let item of selectedJobsites | slice: 0 : 2; let i = index">
					<span class="jobsite-selected-name"> {{ item.Name }}</span>
				</div>
				<div *ngIf="selectedJobsites.length > 2" class="max-content">
					{{ selectedJobsites.length - 2 }} {{ localizedLabels.moreSelected }}
				</div>
			</div>
		</ng-container>
	</div>

	<div *ngIf="isListVisible" class="dropdown-container fcolumn-start-stretch">
		<div class="search-container frow-between-center" *ngIf="jobsites?.length > 0">
			<input class="search-input qa-label-find" [(ngModel)]="searchString" placeholder="{{ localizedLabels.find }}" />
			<div class="frow-start-center">
				<cramo-icon iconName="search" />
			</div>
		</div>

		<cdk-virtual-scroll-viewport itemSize="60" class="jobsites-viewport">
			<div
				*cdkVirtualFor="
					let i = index;
					let js;
					of: jobsites | filter: filterFunction : searchString : null | sort: 'Name' : 1 | sort: 'IsFavorite' : -1;
					trackBy: trackByFn
				"
				class="d-flex justify-content-start jobsite-row"
				(click)="selectJobsite(js)"
				[ngClass]="{
					selected: js.JobsiteId === selectedOption?.Id,
					'frow-start-center': multiSelectMode,
					'multi-select': multiSelectMode,
				}"
				attr.data-test-id="jobsite-option-{{ i }}"
			>
				<div class="d-flex align-self-center w-100">
					<div *ngIf="multiSelectMode" class="d-flex align-self-center align-items-center flex-column">
						<input type="checkbox" class="select-js" [checked]="selectedJobsites.includes(js)" />
					</div>
					<div class="d-flex flex-column" class="jobsite-text">
						<div class="jobsite-name mb-1">
							<img
								*ngIf="js.IsFavorite"
								class="favorites-icon"
								src="/assets/icons/icon_star_full.svg"
								alt="Favorite jobsite"
							/>
							{{ js.Name }}
						</div>
						<div class="jobsite-address">
							{{ js.Address!.StreetAddress }} {{ js.Address!.Zip }} {{ js.Address!.City }}
						</div>
					</div>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>

		<cramo-overlay *ngIf="!isLoadingJobsites && jobsites?.length === 0 && !multiSelectMode">
			<div class="fcolumn-center-center p-5" *ngIf="canAddJobsite">
				<span class="create-jobsite-info mb-3">{{ localizedLabels.noJobSitesInfo }}</span>
				<button (click)="openCreateJobsiteModal()" class="btn btn-create frow-center-center">
					<div class="add-icon mr-2">
						<cramo-icon iconName="add" />
					</div>
					{{ localizedLabels.create }}
				</button>
			</div>
			<div class="fcolumn-center-center p-5" *ngIf="!canAddJobsite">
				<span class="create-jobsite-info mb-3">{{ localizedLabels.noJobSitesInfoNotAllowedToAdd }}</span>
			</div>
		</cramo-overlay>

		<cramo-loading-area
			*ngIf="isLoadingJobsites"
			[showTransparent]="true"
			[title]="localizedLabels.loadingJobsites"
			[backgroundColor]="'#ffffff'"
		/>

		<div class="add-jobsite-container" *ngIf="canAddJobsite && !multiSelectMode && jobsites?.length > 0">
			<div class="new-jobsite-btn cursor frow-between-center" (click)="openCreateJobsiteModal()">
				<div class="add-icon frow-center-center">
					<cramo-icon iconName="add" />
				</div>
				<span class="jobsite-title qa-label-create">{{ localizedLabels.create }}</span>
			</div>
		</div>

		<div class="multi-select-footer-container frow-between-center" *ngIf="multiSelectMode">
			<span class="jobsites-selected">
				{{ selectedJobsites.length }}
				{{ selectedJobsites.length === 1 ? localizedLabels.oneJobsitesSelected : localizedLabels.jobsitesSelected }}
			</span>

			<button
				class="cramo-btn cramo-btn-link cramo-btn-sm"
				[ngClass]="{ 'd-none': selectedJobsites.length > 0 }"
				(click)="selectAllJobsites()"
			>
				{{ localizedLabels.selectAll }}
			</button>
			<button
				class="cramo-btn cramo-btn-link cramo-btn-sm"
				[ngClass]="{ 'd-none': selectedJobsites.length === 0 }"
				(click)="clearSelectedJobsite()"
			>
				{{ localizedLabels.clear }}
			</button>
		</div>
	</div>
</div>
