import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Image } from '../../types/product.types';

@Component({
	selector: 'cramo-image-carousel',
	templateUrl: './image-carousel.component.html',
	styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnDestroy {
	constructor(
		private dialog: MatDialog,
		private sanitizer: DomSanitizer,
	) {}

	public thumbArray: Image[] = [];
	public currentImageSrc: SafeResourceUrl;
	public currentField: string;
	public leftThumbEnabled = true;
	public rightThumbEnabled = true;
	public showThumbs = false;

	private thumbIndex = 0;
	private _imageArray: Image[] = [];
	public dialogModalRef: MatDialogRef<TemplateRef<null>>;

	@Input() public text = '';
	@Input() public imageName = '';
	@Input() public showEcoBadge = false;
	@Input() public show247Badge = false;
	@Input()
	public set imageArray(value: Image[]) {
		if (value && value.length > 0) {
			this._imageArray = value;
			this.thumbArray = this._imageArray.slice(0, 3);
			this.showThumbs = this._imageArray.length > 1;
			this.selectImage(this._imageArray[0], 0);
		}
	}

	@ViewChild('imageModal', { static: true }) public imageModalTemplate: TemplateRef<null>;

	public showPreview() {
		this.dialogModalRef = this.dialog.open(this.imageModalTemplate, {
			maxWidth: '500px',
			minWidth: '320px',
			width: '100%',
			autoFocus: false,
		});
	}

	public closePriceDetailsModal(): void {
		if (this.dialogModalRef) {
			this.dialogModalRef.close();
		}
	}

	public selectImage(image: Image, index: number) {
		this.currentField = image.field;
		this.currentImageSrc =
			image.field === 'video' ? this.sanitizer.bypassSecurityTrustResourceUrl(image.url) : image.url;
		this.thumbIndex = index;
		this.checkArrowEnabled();
	}

	public scrollLeft() {
		if (this.thumbIndex > 0) {
			this.thumbIndex--;
		}

		this.thumbArray =
			this._imageArray.length <= 3 ? this._imageArray : this._imageArray.slice(this.thumbIndex, this.thumbIndex + 3);
		this.selectImage(this._imageArray[this.thumbIndex], this.thumbIndex);
	}

	public scrollRight() {
		if (this.thumbIndex < this._imageArray.length - 1) {
			this.thumbIndex++;
		}

		this.thumbArray =
			this._imageArray.length <= 3 ? this._imageArray : this._imageArray.slice(this.thumbIndex, this.thumbIndex + 3);
		this.selectImage(this._imageArray[this.thumbIndex], this.thumbIndex);
	}

	private checkArrowEnabled() {
		if (this.thumbIndex >= this._imageArray.length - 1) {
			this.rightThumbEnabled = false;
		} else {
			this.rightThumbEnabled = true;
		}

		if (this.thumbIndex < 1) {
			this.leftThumbEnabled = false;
		} else {
			this.leftThumbEnabled = true;
		}
	}

	ngOnDestroy(): void {
		if (this.dialogModalRef) {
			this.dialogModalRef.close();
		}
	}
}
