<div class="flip-container" [ngClass]="{ 'flip-container--bring-to-front': bringToFront }">
	<div (click)="open()" class="fcolumn-start-stretch" [ngClass]="{ disabled: disabled }" #flipCardTrigger>
		<ng-content select="[trigger]" />
	</div>
	@if (showCardFlag) {
		<div
			class="flip-card"
			#flipCardBody
			@cardVisibility
			(@cardVisibility.done)="animationDone()"
			[cramoClickOutside]="showCardFlag && !isModalOpen"
			(byOutsideClicked)="closeCard()"
			[style.minWidth.px]="minWidth"
			[ngClass]="{ 'left-align': alignLeft, 'right-align': !alignLeft }"
		>
			<ng-container [ngTemplateOutlet]="contentTemplate" />
		</div>
	}
</div>

<ng-template #contentTemplate>
	<ng-content select="[body]" />
</ng-template>

<ng-template #contentModalOnlyTemplate>
	<ng-content select="[body-modal-only]" />
</ng-template>

<ng-template #flipPanelModal>
	<cramo-modal (byClose)="closeModal()">
		<div modal-body>
			<ng-container [ngTemplateOutlet]="contentModalOnlyTemplate" />
			<div style="margin: 0 -1rem">
				<ng-container [ngTemplateOutlet]="contentTemplate" />
			</div>
		</div>
	</cramo-modal>
</ng-template>
