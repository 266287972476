import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Signal } from '@angular/core';
import moment from 'moment-mini';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AvailabilityUtilsService } from '../../services/availability-utils.service';
import { CartOldService } from '../../services/cart/cart-old.service';
import { DepotService } from '../../services/depot/depot.service';
import { LogisticsRulesDto } from '../../services/epi/epi.model';
import { EpiService } from '../../services/epi/epi.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user/user.service';
import { DepotProductQuantityMap } from '../../types/cart-old.types';
import { Configurations } from '../../types/configuration.types';
import { Depot } from '../../types/depot.types';
import { AvailabilityResponseDepot, AvailabilityResponseDto } from '../../types/product.types';

@Component({
	selector: 'cramo-depot-select',
	templateUrl: './depot-select.component.html',
	styleUrls: ['./depot-select.component.scss'],
})
export class DepotSelectComponent implements OnInit, OnDestroy {
	private webdepots$: Observable<Depot[]>;

	constructor(
		private depotService: DepotService,
		private productService: ProductService,
		private availabilityUtils: AvailabilityUtilsService,
		private epiService: EpiService,
		private userService: UserService,
		private cartService: CartOldService,
	) {
		this.webdepots$ = this.depotService.webdepots$;
		this.configurations = this.epiService.configurationData;

		this.userService.isLoggedIn$.pipe(takeUntil(this.onDestroy$)).subscribe((isAuthenticated) => {
			this.userAuthenticated = isAuthenticated;
		});

		this.depotCartProductQuantities = this.cartService.depotCartProductQuantities;
	}
	public appData$ = this.epiService.appData$;
	public depotMap: Record<string, DepotSelectDepot> = {};
	public depotsAvailability: AvailabilityResponseDto;
	public allDepots: AvailabilityResponseDepot[] = [];
	public boxDepots: AvailabilityResponseDepot[] = [];
	public selectedAvailability: number;
	public isListOpen = false;
	public isLoadingAvailability = true;
	public depotCartProductQuantities: Signal<DepotProductQuantityMap>;
	private configurations: Configurations;
	private userAuthenticated = false;
	private onDestroy$ = new ReplaySubject(1);
	private depotsOpenOnSaturdays: string[] = [];

	protected logisticsRules: LogisticsRulesDto;

	@Input() public elementId = 'depot-selector';
	@Input() public disabled = false;
	@Input() public filter: 'none' | 'box';
	@Input() public productNumber: string;
	@Input() public selectedDepot: Depot | null;

	ngOnInit() {
		this.logisticsRules = this.epiService.logisticsRules;

		this.depotsOpenOnSaturdays = this.logisticsRules.LogisticRules.PickupRules.DepotsOpenOnSaturdays?.split(',') ?? [];

		this.setArrayOfDepotStrings();

		const date = this.availabilityUtils.addWeekDays(moment(), 2, this.configurations.calendarHolidays);

		if (this.userAuthenticated) {
			const availabilityData$ = this.productService.getCachedAvailability(
				date.format('YYYY-MM-DD'),
				this.productNumber,
			);

			availabilityData$
				.pipe(
					take(1),
					finalize(() => (this.isLoadingAvailability = false)),
				)
				.subscribe((availability: AvailabilityResponseDto[]) => {
					this.depotsAvailability = availability[0];
					this.allDepots = [...this.depotsAvailability.Depots];
					this.boxDepots = this.depotsAvailability.Depots.filter((depot) => Boolean(depot.HasBoxDelivery));
					this.setCurrentDepotAvailability();
					this.sortAvailabilityOnDepotName();
					if (this.selectedDepot) {
						this.byDepotSelected.emit({ depot: this.selectedDepot, availability: this.selectedAvailability });
					}
				});
		}
	}

	@Output() public byDepotSelected: EventEmitter<{ depot: Depot; availability: number }> = new EventEmitter();

	private sortAvailabilityOnDepotName() {
		const ascendingDirection = 1;

		if (this.filter === 'none') {
			if (this.allDepots.length === 0) {
				this.disabled = true;
			} else {
				this.allDepots.sort(
					(leftAvailability: AvailabilityResponseDepot, rightAvailability: AvailabilityResponseDepot): number => {
						const aLowerCase = leftAvailability ? String(this.depotMap[leftAvailability.Depot].Name).toLowerCase() : '';
						const bLowerCase = rightAvailability
							? String(this.depotMap[rightAvailability.Depot].Name).toLowerCase()
							: '';
						const compareMultipier = aLowerCase < bLowerCase ? -1 : aLowerCase > bLowerCase ? 1 : 0;
						return compareMultipier * ascendingDirection;
					},
				);
			}
		} else {
			if (this.boxDepots.length === 0) {
				this.disabled = true;
			} else {
				this.boxDepots.sort(
					(leftAvailability: AvailabilityResponseDepot, rightAvailability: AvailabilityResponseDepot): number => {
						const aLowerCase = leftAvailability ? String(this.depotMap[leftAvailability.Depot].Name).toLowerCase() : '';
						const bLowerCase = rightAvailability
							? String(this.depotMap[rightAvailability.Depot].Name).toLowerCase()
							: '';
						const compareMultipier = aLowerCase < bLowerCase ? -1 : aLowerCase > bLowerCase ? 1 : 0;
						return compareMultipier * ascendingDirection;
					},
				);
			}
		}
	}

	private setCurrentDepotAvailability() {
		if (this.selectedDepot) {
			this.depotsAvailability?.Depots.forEach((depot) => {
				if (depot.Depot === this.selectedDepot.Id) {
					this.selectedAvailability = depot.QuantityPossibleAvailableNoReturn;
				}
			});
		} else {
			this.selectedAvailability = 0;
		}
	}

	private setArrayOfDepotStrings() {
		this.webdepots$.pipe(take(1)).subscribe((depots) => {
			depots.forEach((depot) => {
				this.depotMap[depot.Id] = { ...depot, IsOpenOnSaturdays: this.depotsOpenOnSaturdays.includes(depot?.IDSet.ID) };
			});
		});
	}

	public isOpenOnSaturdays(depot: Depot) {
		return this.depotsOpenOnSaturdays.includes(depot?.IDSet.ID);
	}
	public selectDepot(depotId: string, availability: number) {
		this.selectedDepot = this.depotMap[depotId];
		this.selectedAvailability = availability;
		this.byDepotSelected.emit({ depot: this.selectedDepot, availability: availability });
		this.hideList();
	}

	public openList() {
		this.isListOpen = true;
		setTimeout(() => {
			const selectedDepot = document.getElementsByClassName('depot-item selected');
			if (selectedDepot.length === 1) {
				const target = selectedDepot[0] as HTMLElement;
				if (target.parentNode instanceof HTMLElement) {
					target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop;
				}
			}
		}, 0);
	}

	public hideList() {
		setTimeout(() => {
			this.isListOpen = false;
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}

export type DepotSelectDepot = Depot & {
	IsOpenOnSaturdays: boolean;
};
