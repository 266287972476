<div class="add-to-cart__container" data-test-id="add-to-cart-container" *ngIf="appData$ | async as appData">
	<div class="p-3">
		<div class="add-to-cart__group">
			<label>{{ appData.cart.chooseDeliveryOption }}</label>
			<div class="custom-radio__group">
				<div
					class="custom-control custom-radio"
					data-test-id="delivery-type-pickup"
					[ngClass]="{
						'custom-radio__active': selectedDeliveryType() === deliveryType.PICKUP,
						'custom-radio__disabled': updatingOrder || addingToCart,
					}"
				>
					<input
						type="radio"
						[id]="deliveryMethodRadioId1"
						[name]="deliveryMethodRadioName"
						[checked]="selectedDeliveryType() === deliveryType.PICKUP"
						(change)="onDeliveryChange(deliveryType.PICKUP)"
						[value]="deliveryType.PICKUP"
						[disabled]="updatingOrder"
						class="custom-control-input"
					/>
					<label class="custom-control-label" [for]="deliveryMethodRadioId1">
						{{ appData.global.pickUp }}
						<div class="custom-radio__icon">
							<cramo-icon iconName="location_on" [size]="20" />
						</div>
					</label>

					@if (
						featureToggles().order.isPickupTypeEnabled &&
						selectedDepotHasBox &&
						is247Product &&
						selectedDeliveryType() === deliveryType.PICKUP
					) {
						<section class="pickup-type-section" *cramoHasPermission="'RENT_BOX_PICKUP'">
							<input
								type="radio"
								id="pickupOption1"
								name="pickupOptions"
								[checked]="selectedPickupType === pickupType.STORE"
								(change)="onPickupTypeChange(pickupType.STORE)"
								[value]="pickupType.STORE"
								class="custom-control-input"
							/>
							<label class="custom-control-label pickup-option" for="pickupOption1">{{ appData.global.inStore }}</label>
							<input
								type="radio"
								id="pickupOption2"
								name="pickupOptions"
								[checked]="selectedPickupType === pickupType.BOX"
								(change)="onPickupTypeChange(pickupType.BOX)"
								[value]="pickupType.BOX"
								class="custom-control-input"
							/>
							<label class="custom-control-label pickup-option" for="pickupOption2">{{
								appData.global.inRentalBox
							}}</label>
						</section>
					}
				</div>

				<div
					*cramoHasPermission="'RENT_TRANSPORT'"
					class="custom-control custom-radio"
					data-test-id="delivery-type-transport"
					[ngClass]="{
						'custom-radio__active': selectedDeliveryType() === deliveryType.TRANSPORT,
						'custom-radio__disabled': updatingOrder || addingToCart,
					}"
				>
					<input
						type="radio"
						[id]="deliveryMethodRadioId2"
						[name]="deliveryMethodRadioName"
						[checked]="selectedDeliveryType() === deliveryType.TRANSPORT"
						(change)="onDeliveryChange(deliveryType.TRANSPORT)"
						[value]="deliveryType.TRANSPORT"
						class="custom-control-input"
						[disabled]="updatingOrder || addingToCart"
					/>
					<label class="custom-control-label" [for]="deliveryMethodRadioId2">
						{{ appData.global.transport }}
						<div class="custom-radio__icon">
							<cramo-icon iconName="local_shipping" [size]="20" />
						</div>
					</label>
				</div>
			</div>
		</div>

		@if (selectedDeliveryType() === deliveryType.PICKUP) {
			<div class="add-to-cart__group">
				<label for="depot-selector">{{ appData.depot.yourCurrentDepot }}</label>
				<!-- Two way binding would replace the complexity with passing both a depotSelect function and selectedDepot -->
				@if (product?.Properties.General.BrandTypeItemNumber.Value) {
					<cramo-depot-select
						[filter]="depotFilter"
						[elementId]="'depot-selector'"
						[disabled]="addingToCart || isLoading || updatingOrder"
						[selectedDepot]="currentDepot"
						[productNumber]="product.Properties.General.BrandTypeItemNumber.Value"
						(byDepotSelected)="onDepotSelected($event)"
					/>
				}
			</div>
		}

		<div class="add-to-cart__group">
			<label for="jobsite-selector">{{ appData.cart.chooseJobsite }}</label>
			<cramo-search-select
				elementId="jobsite-selector"
				[largeSize]="true"
				[disabled]="isPunchoutAndHasItemsInCart() || !jobsiteEnabled || updatingOrder || addingToCart"
				[showSelectedState]="!isOrder"
				[ngClass]="{ hidden: canOnlyRentPickup }"
				(bySelect)="onJobsiteChange($event)"
				(byChangeModalState)="setModalState($event)"
			/>
		</div>

		@if (!isMerchandise) {
			<div class="add-to-cart__group add-to-cart__group--calendar">
				<div>
					<label for="start-date">{{ appData.addToCart.startDate }}</label>
					<cramo-calendar
						[showSelectedState]="!isOrder"
						[loading]="checkingProductAvailability"
						[height]="48"
						[elementId]="'start-date'"
						[calendarOptions]="startCalendar"
						[disabled]="
							!startCalendarEnabled ||
							isLoading ||
							updatingOrder ||
							addingToCart ||
							(!currentDepot && selectedDeliveryType() !== deliveryType.TRANSPORT)
						"
						[alignLeft]="true"
						[error]="!isOrderDateValid"
						(byDateChange)="onStartDateChange($event)"
						[pickupType]="selectedPickupType"
					/>
				</div>
				<div data-test-id="calendar-container-end-date">
					<label for="end-date">{{ appData.addToCart.endDate }}</label>
					<cramo-calendar
						[showSelectedState]="!isOrder"
						[loading]="checkingProductAvailability"
						[height]="48"
						[elementId]="'end-date'"
						[calendarOptions]="endCalendar"
						[disabled]="
							!endCalendarEnabled ||
							isLoading ||
							updatingOrder ||
							addingToCart ||
							(!currentDepot && selectedDeliveryType() !== deliveryType.TRANSPORT)
						"
						(byDateChange)="onEndDateChange($event)"
						[alignLeft]="false"
					/>
				</div>
			</div>

			<div class="add-to-cart__group">
				<label>{{ appData.global.quantity }}</label>
				<cramo-stepper-old
					[showSelectedState]="!isOrder"
					[stepperOptions]="stepperOptions"
					[disabled]="!quantityEnabled || updatingOrder || addingToCart"
					(byValueChange)="onQuantityChanged($event)"
				/>
			</div>
		}

		@if (isMerchandise) {
			<div class="add-to-cart__group">
				<label for="start-date">{{
					selectedDeliveryType() === deliveryType.PICKUP ? appData.addToCart.pickupDate : appData.addToCart.deliveryDate
				}}</label>
				<cramo-calendar
					[showSelectedState]="!isOrder"
					[loading]="checkingProductAvailability"
					[height]="48"
					[elementId]="'start-date'"
					[calendarOptions]="startCalendar"
					[disabled]="
						!startCalendarEnabled ||
						isLoading ||
						updatingOrder ||
						addingToCart ||
						(!currentDepot && selectedDeliveryType() !== deliveryType.TRANSPORT)
					"
					[alignLeft]="true"
					[error]="!isOrderDateValid"
					(byDateChange)="onStartDateChange($event)"
					[pickupType]="selectedPickupType"
				/>
			</div>
		}

		@if (selectedDeliveryType() === deliveryType.TRANSPORT) {
			<div>
				<div class="add-to-cart__group">
					<label for="delivery-time-selector">{{ appData.addToCart.deliveryTime }}</label>
					<select
						#deliveryTimeSelector
						id="delivery-time-selector"
						class="add-to-cart__select form-control"
						data-test-id="transport-time-selector"
						[ngClass]="{ 'add-to-cart__select--selected': deliveryTimeSelector.value }"
						[disabled]="!timeEnabled || updatingOrder || addingToCart"
						[(ngModel)]="selectedTime"
						(change)="onTimeChanged()"
					>
						<option value="" disabled selected>{{ appData.global.select }}</option>
						<option *ngFor="let deliveryTime of deliveryTimes" [value]="deliveryTime.From + ' - ' + deliveryTime.To">
							{{ deliveryTime.From }} - {{ deliveryTime.To }}
						</option>
					</select>
				</div>
			</div>
		}

		@if (isMerchandise) {
			<div class="row add-to-cart__group">
				<div class="col-6">
					<label>{{ appData.global.quantity }}</label>
					<cramo-stepper-old
						[showSelectedState]="!isOrder"
						[stepperOptions]="stepperOptions"
						[disabled]="!quantityEnabled || updatingOrder || addingToCart"
						(byValueChange)="onQuantityChanged($event)"
					/>
				</div>
				<div class="col-6">
					<div>
						<label>{{ appData.global.unit }}</label>
					</div>
					<div>
						<select
							[(ngModel)]="selectedUnit"
							class="add-to-cart__select form-control"
							[disabled]="!quantityEnabled || updatingOrder || addingToCart"
							(change)="onUnitChanged($event)"
						>
							<option
								*ngFor="let price of pricesArray"
								[value]="price.Packaging.PackagingName"
								[selected]="price.Packaging.PackagingName === selectedUnit"
								[disabled]="disablePriceUnitOption(price)"
							>
								{{ priceUtils.getUnitTranslation({ unit: price.Packaging.PackagingName, language: currentLanguage }) }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="row add-to-cart__group">
				<div class="col-6">{{ appData.product.priceExcludingVAT }}</div>
				@if (pricesArray.length && selectedUnit) {
					<div class="col-6 add-to-cart__price">
						<span>{{ this.getTotalPrice() | number: '1.2-2' }}</span>
						<span class="add-to-cart__price__currency">{{ pricesArray[0].Currency }}</span>
					</div>
				}
			</div>
		}

		<div class="add-to-cart__group add-to-cart__group--actions">
			@if (!isOrder) {
				<cramo-button
					data-test-id="add-to-cart-button"
					buttonColor="primary"
					(onclick)="addToCart()"
					[isDisabled]="!addToCartEnabled || addingToCart || stepperComponent?.error || updatingOrder"
					[isLoading]="addingToCart"
					text="{{ appData.global.add }} {{ selectedQuantity }} {{ appData.addToCart.addTheseToCart }}"
					[fullWidth]="true"
				/>
			} @else {
				<cramo-button
					buttonColor="primary"
					(onclick)="saveOrder()"
					[isDisabled]="
						!addToCartEnabled ||
						stepperComponent?.error ||
						!isOrderDateValid ||
						updatingOrder ||
						(isMerchandise && !selectedUnit)
					"
					[isLoading]="updatingOrder"
					text="{{ appData.global.save }}"
					[fullWidth]="true"
				/>

				<cramo-button
					(onclick)="cancel()"
					text="{{ appData.global.cancel }}"
					[fullWidth]="true"
					buttonStyle="stroked"
				/>
			}
		</div>
	</div>
</div>
