<article #productCardSelector class="product-card" data-test-id="product-card">
	<a class="product-card-content ga-productcard-image" [routerLink]="productLink">
		<div class="product-card-image">
			@if (productFiles) {
				<img class="skeleton-loading" [src]="product?.ImageUrl + '?width=500'" loading="lazy" [alt]="product?.Name" />
			}
		</div>
		<div class="product-card-title">{{ product?.Name }}</div>
		<div class="product-card-brand">
			{{ product?.Properties?.General?.BrandTypeDisplayBrand?.Value }}
			{{ product?.Properties?.General?.BrandTypeType?.Value }}
		</div>
		<div class="product-card-badge product-card-top-right">
			@if (product?.Properties?.Status?.BrandTypeEcoChoiseLabel?.Value) {
				<img class="eco-badge" src="/assets/images/cramo_eco_choice.svg" alt="Cramo eco badge" />
			}
		</div>
		<div class="product-card-badge product-card-top-left">
			@if (is247Product) {
				<div class="twentyfourseven-badge">24/7</div>
			}
		</div>
	</a>

	@if (showPrice && !fetchingPricesFailed) {
		<div class="product-card-price ga-productcard-price">
			<cramo-product-price [prices]="prices()" [product]="product" />
		</div>
	}

	<div class="product-card-actions">
		@if (showLogin) {
			<cramo-button
				buttonColor="primary"
				[fullWidth]="true"
				(onclick)="login()"
				text="{{ isMerchandise ? appData.login?.loginToBuy : appData.login?.marketLoginMessage }}"
			/>
		}

		@if (showContactUs) {
			<cramo-flip-panel class="product-flip-panel" [minWidth]="300" [alignLeft]="true">
				<cramo-button
					trigger
					data-test-id="product-card-button-contact"
					buttonColor="primary"
					[fullWidth]="true"
					text="{{ appData.addToCart.unrentableLabel }}"
				/>
				<div body>
					<cramo-contact-us />
				</div>
			</cramo-flip-panel>

			@if (featureToggles().other.areSavedProductsEnabled && hasLoadedSavedProducts) {
				<cramo-save-product-button [productId]="product.Id" class="ga-productcard-saveproduct" />
			}
		}

		@if (!showLogin && !showContactUs) {
			@if (featureToggles().other.isNewCartEnabled) {
				<cramo-button
					[text]="rentLabel"
					buttonColor="primary"
					[fullWidth]="true"
					[isDisabled]="prices() === null"
					[isLoading]="isAddingToCart()"
					(onclick)="addToCart(product.Id)"
				/>
			} @else {
				<cramo-flip-panel
					class="product-flip-panel"
					[minWidth]="380"
					[alignLeft]="true"
					[disabled]="!canRent || prices() === null"
					[overrideFlip]="!isCreditApproved()"
				>
					<cramo-button
						trigger
						buttonColor="primary"
						[fullWidth]="true"
						[text]="rentLabel"
						[isDisabled]="prices() === null || isCreditApproved() === undefined"
						(onclick)="openCreditCheckModal()"
						data-test-id="product-card-rent-button"
					/>

					<div body-modal-only>
						<div class="product-preview">
							<img class="product-preview-img" [src]="product?.ImageUrl + '?width=300'" alt="Product image" />
							<div class="product-preview-desc">
								<div class="product-preview-title">
									{{ product?.Name }}
								</div>
								<div class="product-preview-subtitle">
									{{ product?.Properties?.General?.BrandTypeDisplayBrand?.Value }}
									{{ product?.Properties?.General?.BrandTypeType?.Value }}
								</div>
							</div>
						</div>
					</div>
					<div body>
						<cramo-add-to-cart [options]="productOptions" [prices]="prices()" />
					</div>
				</cramo-flip-panel>
			}
			@if (featureToggles().other.areSavedProductsEnabled && hasLoadedSavedProducts) {
				<cramo-save-product-button [productId]="product.Id" />
			}
		}
	</div>

	@if (isLoggedIn && canRent && showAvailability) {
		<div class="product-card-availability">
			<cramo-product-availability [availability]="availability" />
		</div>
	}
</article>
