<div class="contact-us-container" data-test-id="contact-us-container">
	<div class="contact-us-header">{{ appData?.product?.unrentableContactText }}</div>

	<div class="contact-info fcolumn-center-center">
		<div class="icon-contact-container">
			<img class="icon-contact" src="/assets/icons/icon_contact.svg" alt="" />
		</div>
		<a class="contact-btn frow-start-center" href="tel:{{ contactInfoForMarket?.contactPhoneNumber }}">
			<img class="icon-smartphone" src="/assets/icons/icon_smartphone.svg" alt="" />
			<span>{{ contactInfoForMarket?.contactPhoneNumber }}</span>
		</a>
		<a class="contact-btn frow-start-center" href="mailto:{{ contactInfoForMarket?.contactEmail }}">
			<img class="icon-email" src="/assets/icons/icon_cont_email.svg" alt="" />
			<span class="break-word">{{ contactInfoForMarket?.contactEmail }}</span>
		</a>
		<div class="contact-btn frow-start-center" (click)="goToDepotMap()">
			<img class="icon-map-marker" src="/assets/icons/icon_map_marker.svg" alt="Goto depot map" />
			<span>{{ appData?.global?.findClosestDepotForContact }}</span>
		</div>
	</div>
</div>
